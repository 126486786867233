import { IntlProvider } from 'react-intl';
import enTranslation from './translations/en.json';
import frTranslation from './translations/fr.json';
import React from 'react';
import KEYS from './utils/key';

const messageKeys = { fr: frTranslation, en: enTranslation };

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    // this.switchToEnglish = () =>
    //   this.setState({ locale: "en", messages: enTranslation });

    // this.switchToDeutsch = () =>
    //   this.setState({ locale: "de", messages: deTranslation });

    const defaultLocale = navigator.language.split(/[-_]/)[0]; // language without region code
    const userLocal = localStorage.getItem(KEYS.userLocale) || defaultLocale;
    localStorage.setItem(KEYS.userLocale, userLocal);
    const messages = !(userLocal in messageKeys) ? frTranslation : messageKeys[userLocal];
    this.state = {
      locale: userLocal,
      messages: messages
    };
    this.manageLanguage = this.manageLanguage.bind(this);
  }
  manageLanguage(event) {
    let userLocal = event.detail.value || this.state.locale;
    if (!(userLocal in messageKeys)) userLocal = 'fr';
    this.setState({ locale: userLocal, messages: messageKeys[userLocal] });
    localStorage.setItem(KEYS.userLocale, userLocal);
  }
  componentDidMount() {
    document.addEventListener('language-change', this.manageLanguage.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('language-change', this.manageLanguage.bind(this));
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="fr">
        {children}
      </IntlProvider>
    );
  }
}

export default IntlProviderWrapper;
