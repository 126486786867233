import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useState, useEffect } from 'react';
import AppToolBar from './components/AppToolBar';
import { Route, Routes, Navigate } from 'react-router-dom';
import Page404 from './components/shared/Page404';
import UserModule from './modules/user/UserModule';
import AuthService from './modules/user/services/authservice';
import SpaceModule from './modules/space/SpaceModule';
import AdminModule from './modules/admin/AdminModule';

import { useDispatch, useSelector } from 'react-redux';
import { selectConStatus, setCon } from './stores/slices/connectionSlice';
import { selectUser } from './stores/slices/userSlice';
import AppProgress from './components/shared/AppProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: '#303F9F'
    },
    secondary: {
      main: orange[500]
    }
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          height: 64,
          zIndex: 3
        }
      }
    }
  },
  custom: {
    toolbarHeight: '64'
  }
});

/**
 * Started app function
 *
 * @param {any} props
 * @return {undefined}
 */
function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  const [message, setMessage] = useState('');

  const [setIsNetworkProbleme] = useState(false);

  const user = useSelector(selectUser);

  const isConnected = useSelector(selectConStatus);

  const isAdmin = () => user != null && user.role === 'ADMIN';
  console.log('App/isAdmin =  ', isAdmin());

  const redirectUrl = window.location.pathname + '' + window.location.search;

  const dispatch = useDispatch();

  useEffect(() => {
    AuthService.checkConnected().then(
      (data) => {
        setIsLoading(false);
        if (data?.code === 'CONNECTED') {
          dispatch(setCon(true));
        }
      },
      (error) => {
        setIsLoading(false);
        if (error.code === 'ERR_BAD_REQUEST' || error.code === 'UNAUTHORIZED') {
          dispatch(setCon(false));
        } else {
          setIsNetworkProbleme(true);
          setMessage('impossible de se connecter au serveur ');
        }
      }
    );
  }, [dispatch, setIsNetworkProbleme]);

  return (
    <ThemeProvider theme={theme}>
      <AppToolBar />
      <h4>{message}</h4>
      {isLoading && <AppProgress />}
      {!isLoading && (
        <div className="App-content">
          <div className="layout vertical">
            <div className="layout vertical" style={{ minHeight: 'calc(100vh - 128px)' }}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    isConnected ? (
                      isAdmin() ? (
                        <Navigate to="/admin" replace />
                      ) : (
                        <Navigate to="/space" replace />
                      )
                    ) : (
                      <Navigate
                        to={{
                          pathname: '/user/login',
                          search: '?redirect_url=' + encodeURIComponent(redirectUrl),
                          state: {}
                        }}
                        replace
                      />
                    )
                  }
                />
                <Route path="/user/*" element={<UserModule />} />
                <Route
                  path="/space/*"
                  element={
                    isConnected ? (
                      <SpaceModule />
                    ) : (
                      <Navigate
                        to={{
                          pathname: '/user/login',
                          search: '?redirect_url=' + encodeURIComponent(redirectUrl),
                          state: {}
                        }}
                        replace
                      />
                    )
                  }
                />
                <Route
                  path="/admin/*"
                  element={
                    isConnected && isAdmin ? (
                      <AdminModule />
                    ) : (
                      <Navigate
                        to={{
                          pathname: '/user/login',
                          search: '?redirect_url=' + encodeURIComponent(redirectUrl),
                          state: {}
                        }}
                        replace
                      />
                    )
                  }
                />
                <Route
                  path="*"
                  element={<Page404 source={{ type: 'unknow', content: '/some_url' }} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
