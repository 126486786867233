import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import { itemToData } from 'dynamo-converters';
import React from 'react';
import { useState } from 'react';
import { red } from '@mui/material/colors';
import { useIntl } from 'react-intl';
import { cancelEventPropagation, convertTopicPathToXressource } from '../../../../../utils/Utils';
import { useSnackbar } from 'notistack';
import QuestionService from '../../../services/question-service';
import QuestionItemGeneric from '../../../../../components/shared/question/QuestionItemGeneric';
// import EnumVersion from '../../../../../utils/EnumVersion';

function QuestionNotifList(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [statusChange, setStatusChange] = useState(false);
  const [openStatusChange, setOpenStatusChange] = useState(false);
  const [question, setQuestion] = useState({});
  const [families, setFamilies] = useState(props.families);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenStatusChange(false);
  };
  const renderButtons = (question) => {
    const Actions = '';
    switch (question.apply) {
      case 'YES':
        return (
          <div
            style={{
              display: 'flex',
              justifyItems: 'right',
              justifyContent: 'space-between'
            }}>
            <Button
              variant="contained"
              disableElevation
              size="small"
              sx={{
                width: 'max-content',
                textTransform: 'none',
                bgcolor: red[500],
                '&:hover': {
                  bgcolor: red[700]
                }
              }}
              onClick={(e) => onClickBackToPrevious(e, question)}>
              {formatMessage({ id: 'previous_version' })}
            </Button>
          </div>
        );
      /* case 'NO':
        return (
          <div
            style={{
              display: 'flex',
              justifyItems: 'right',
              justifyContent: 'space-between'
            }}>
            <Button
              variant="contained"
              color="success"
              disableElevation
              size="small"
              sx={{ width: 'max-content', textTransform: 'none' }}
              onClick={(e) => onClickAccept(e, question)}>
              {formatMessage({ id: 'accept_changes' })}
            </Button>
          </div>
        ); */
      case 'NO':
        return (
          <div
            style={{
              display: 'flex',
              justifyItems: 'right',
              justifyContent: 'space-between'
            }}>
            {/* <Button
              variant="contained"
              color="success"
              disableElevation
              size="small"
              sx={{ width: 'max-content', textTransform: 'none' }}
              onClick={(e) => onClickAccept(e, question)}>
              {formatMessage({ id: 'accept_changes' })}
            </Button> */}
          </div>
        );
      default:
        return (
          <div
            style={{
              display: 'flex',
              justifyItems: 'right',
              justifyContent: 'space-between'
            }}>
            <Button
              variant="contained"
              color="success"
              disableElevation
              size="small"
              sx={{ width: 'max-content', textTransform: 'none' }}
              onClick={(e) => onClickAccept(e, question)}>
              {formatMessage({ id: 'accept_changes' })}
            </Button>
            <Button
              variant="contained"
              color="error"
              disableElevation
              size="small"
              sx={{
                marginLeft: '6px',
                width: 'max-content',
                textTransform: 'none'
              }}
              onClick={(e) => onClickDiscard(e, question)}>
              {formatMessage({ id: 'discard_changes' })}
            </Button>
          </div>
        );
    }
  };

  const onConfirmAcceptChanges = () => {
    setOpenStatusChange(false);
    setStatusChange('');

    console.log('AcceptChanges', question);
    const path = convertTopicPathToXressource(question.topicPath);
    console.log('Path', path);

    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });

    console.log('questionId', question.questionId);
    console.log('le parent= ', props.parent);

    const idFrom = { version: props.idFrom.version, questionId: props.idFrom.questionId }; // Id de la question mise a jour
    const idTo = { version: question.version, questionId: question.questionId }; // Id de la question a mettre a jour

    // QuestionService.acceptChanges(props.idFrom, question.questionId, path, props.isTeacher)
    QuestionService.acceptChanges(idFrom, idTo, path, props.isTeacher)
      .then((response) => {
        if (response?.data?.code === 'SUCCESS') {
          const newFamilies = families.map((element) => {
            if (element.questionId === question.questionId) {
              // element.apply = "YES";
              return itemToData(response.data.current);
            }
            return element;
          });
          setFamilies(newFamilies);
        }
        enqueueSnackbar(formatMessage({ id: 'success_operation' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'unkown_error' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
  };
  const onConfirmDiscardChanges = () => {
    setOpenStatusChange(false);
    setStatusChange('');

    console.log('DiscardChanges', question);
    const path = convertTopicPathToXressource(question.topicPath);
    console.log('Path', path);

    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });

    console.log('questionId', question.questionId);

    const idFrom = { version: props.idFrom.version, questionId: props.idFrom.questionId }; // Id de la question mise a jour
    const idTo = { version: question.version, questionId: question.questionId }; // Id de la question a mettre a jour

    // QuestionService.discardChanges(props.idFrom, question.questionId, path, props.isTeacher)
    QuestionService.discardChanges(idFrom, idTo, path, props.isTeacher)
      .then((response) => {
        if (response?.data?.code === 'SUCCESS') {
          const newFamilies = families.map((element) => {
            if (element.questionId === question.questionId) {
              element.apply = 'NO';
            }
            return element;
          });
          setFamilies(newFamilies);
        }
        enqueueSnackbar(formatMessage({ id: 'success_operation' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);
        console.log('err:', err?.response?.data?.code);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'unkown_error' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
  };
  const onConfirmBackPrevious = () => {
    setOpenStatusChange(false);
    setStatusChange('');
    const path = convertTopicPathToXressource(question.topicPath);
    console.log('Path', path);

    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });

    console.log('questionId', question.questionId);

    // const idFrom = { version: EnumVersion.PREVIOUS, questionId: question.questionId }; // id de la version previous
    // const idTo = { version: question.version, questionId: question.questionId }; // id de la version current

    const idFrom = { version: props.idFrom.version, questionId: props.idFrom.questionId }; // Id de la version notif
    const idTo = { version: question.version, questionId: question.questionId }; // Id de la version current

    // QuestionService.backToPrevious(props.idFrom, question.questionId, path, props.isTeacher)
    QuestionService.backToPrevious(idFrom, idTo, path, props.isTeacher)
      .then((response) => {
        if (response?.data?.code === 'SUCCESS') {
          const newFamilies = families.map((element) => {
            if (element.questionId === question.questionId) {
              // element.apply = "NONE";
              return itemToData(response.data.previous);
            }
            return element;
          });
          setFamilies(newFamilies);
        }
        enqueueSnackbar(formatMessage({ id: 'success_operation' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'unkown_error' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
  };

  const onClickAccept = (event, question) => {
    cancelEventPropagation(event);
    setQuestion(question);
    setOpenStatusChange(true);
    setStatusChange('ACCEPT');
  };
  const onClickDiscard = (event, question) => {
    cancelEventPropagation(event);
    setQuestion(question);
    setOpenStatusChange(true);
    setStatusChange('DISCARD');
  };
  const onClickBackToPrevious = (event, question) => {
    cancelEventPropagation(event);
    setQuestion(question);
    setOpenStatusChange(true);
    setStatusChange('BACK');
  };
  return (
    <>
      <Box
        sx={{
          paddingLeft: '35px',
          marginBottom: '5px'
        }}>
        {families.length > 0 && (
          <div style={{ color: '#656565', marginTop: '10px' }}>
            {formatMessage({ id: 'affected_questions' })}
          </div>
        )}
        {families.map((question, index) => (
          <div key={index} style={{ paddingBottom: '10px' }}>
            <Stack direction="row" spacing={2}>
              <QuestionItemGeneric
                question={question}
                index={index}
                actions={renderButtons(question)}
              />
            </Stack>
          </div>
        ))}
      </Box>
      {/* Dialog Confirm ACCEPT CHANGE and DISCARD CHANGES */}
      <Dialog
        open={['ACCEPT', 'DISCARD', 'BACK'].includes(statusChange) ? openStatusChange : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'confirm_action' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{formatMessage({ id: 'Do_you_really_want' })}</span>
            {statusChange === 'ACCEPT' && <span>{formatMessage({ id: 'allow_changes' })}?</span>}
            {statusChange === 'DISCARD' && <span>{formatMessage({ id: 'reject_changes' })}?</span>}
            {statusChange === 'BACK' && <span>{formatMessage({ id: 'previous_version' })}?</span>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {formatMessage({ id: 'no' })}, {formatMessage({ id: 'cancel' })}
          </Button>
          <Button
            variant="contained"
            onClick={
              statusChange === 'ACCEPT'
                ? onConfirmAcceptChanges
                : statusChange === 'DISCARD'
                ? onConfirmDiscardChanges
                : onConfirmBackPrevious
            }
            autoFocus
            color="primary">
            {formatMessage({ id: 'yes' })}, {formatMessage({ id: 'apply' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuestionNotifList;
