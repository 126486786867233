import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SubjectIcon from '@mui/icons-material/Subject';
import ClassIcon from '@mui/icons-material/Class';
import { Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedCountry } from '../../stores/slices/userSlice';
import { useEffect, useState } from 'react';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
      [`& .${treeItemClasses.label}`]: {
        fontWeight: '600',
        color: 'inherit'
      }
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit'
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 25,
    [`& .${treeItemClasses.content}`]: {
      // paddingLeft: theme.spacing(2),
      paddingLeft: 18
    }
  }
}));

function StyledTreeItem(props) {
  const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, link, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <ChildContent
          link={link}
          content={
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pr: 0 }}>
              <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
              <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                {labelText}
              </Typography>
              <Typography variant="caption" color="inherit">
                {labelInfo}
              </Typography>
            </Box>
          }
        />
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      {...other}
    />
  );
}

function ChildContent(props) {
  if (props.link)
    return (
      <Link
        to={props.link}
        replace="true"
        style={{
          color: 'inherit'
        }}>
        {props.content}
      </Link>
    );
  return <>{props.content}</>;
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
};

export default function AppMenu() {
  const country = useSelector(selectedCountry);

  const location = useLocation();

  const classes = country?.classes;
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    if (location != null) {
      const splitt = location.pathname.split('/');
      let selectNodeIds = '';
      let toogleNodeIds = [];
      const countryCode = splitt.length > 2 ? splitt.at(2).toUpperCase() : null;
      if (countryCode != null && countryCode.length > 0) {
        const classCode = splitt.length > 3 ? splitt.at(3).toUpperCase() : null;
        if (classCode != null && classCode.length > 0) {
          selectNodeIds = countryCode + '_' + classCode;
          toogleNodeIds = [selectNodeIds, ...toogleNodeIds];
          const subjectCode = splitt.length > 4 ? splitt.at(4).toUpperCase() : null;
          if (subjectCode != null && subjectCode.length > 0) {
            selectNodeIds = countryCode + '_' + classCode + '_' + subjectCode;
            toogleNodeIds = [selectNodeIds, ...toogleNodeIds];
            const topicCode = splitt.length > 5 ? splitt.at(5).toUpperCase() : null;
            if (topicCode != null && topicCode.length > 0) {
              selectNodeIds = countryCode + '_' + classCode + '_' + subjectCode + '_' + topicCode;
              // toogleNodeIds = [selectNodeIds, ...toogleNodeIds];
            }
          }
        }
      }
      setSelected(selectNodeIds);
      setExpanded(toogleNodeIds);
    }
  }, [location]);

  return (
    <>
      {classes != null && (
        <Paper elevation={0} sx={{ width: '370px', padding: '10px 10px 10px 0' }}>
          <TreeView
            aria-label="gmail"
            defaultExpanded={['CM_CM2']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{ flexGrow: 1, maxWidth: 350, overflowY: 'none' }}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}>
            {classes != null &&
              Object.values(classes).map((_class, indexClass) => (
                <StyledTreeItem
                  nodeId={country.code + '_' + _class.code}
                  key={country.code + '_' + _class.code}
                  labelText={_class.name}
                  labelIcon={ClassIcon}
                  labelInfo=""
                  color="#1a73e8"
                  bgColor="primary">
                  {Object.values(_class.subjects).map((subject, indexSubject) => (
                    <StyledTreeItem
                      nodeId={country.code + '_' + _class.code + '_' + subject.code}
                      key={country.code + '_' + _class.code + '_' + subject.code}
                      labelText={subject.name}
                      labelIcon={SubjectIcon}
                      labelInfo=""
                      color="#1a73e8"
                      bgColor="primary">
                      {Object.values(subject.topics).map((topic, indexTopic) => (
                        <StyledTreeItem
                          nodeId={
                            country.code + '_' + _class.code + '_' + subject.code + '_' + topic.code
                          }
                          key={
                            country.code + '_' + _class.code + '_' + subject.code + '_' + topic.code
                          }
                          labelText={topic.name}
                          labelIcon={MenuBookIcon}
                          link={(
                            '/space/' +
                            country.code +
                            '/' +
                            _class.code +
                            '/' +
                            subject.code +
                            '/' +
                            topic.code
                          ).toLowerCase()}
                          labelInfo=""
                          color="#1a73e8"
                          bgColor="primary"></StyledTreeItem>
                      ))}
                    </StyledTreeItem>
                  ))}
                </StyledTreeItem>
              ))}
          </TreeView>
        </Paper>
      )}
      {classes === null && <> </>}
    </>
  );
}
