import { AddCircleOutlineOutlined, Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper, useTheme } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppTitle from '../../../../../components/shared/AppTitle';
import { selectNewQuestion } from '../../../../../stores/slices/newQuestionSlice';
import QuestionService from '../../../services/question-service';
import QuestionAddGeneric from './QuestionAddGeneric';
import QuestionAddMulti from './QuestionAddMulti';
import QuestionAddOpen from './QuestionAddOpen';
import QuestionAddSingle from './QuestionAddSingle';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import EnumQuestionType from '../../../../../utils/EnumQuestionType';
import { publishEvent } from '../../../../../utils/Utils';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

function QuestionAdd(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const theme = useTheme();
  console.log('theme', theme);
  const question = useSelector(selectNewQuestion);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const doSubmitForm = (event) => {
    console.log('doSubmit form');
    setErrorMessages([]);
    setIsLoading(true);
    const path = `${props.metadata.country}.${props.metadata.class}.${props.metadata.subject}`;
    const topicPath = `${props.metadata.country}#${props.metadata.class}#${props.metadata.subject}#${props.metadata.topic}`;
    const { content, mark, duration, images, type, version } = question;
    const data = { content, mark, duration, images, type, topicPath, version };
    console.log('data', data);
    switch (data.type) {
      case EnumQuestionType.OPEN:
        data.answer = question.open.answer;
        data.answerTags = question.open.answerTags;
        data.explanation = question.open.explanation;
        break;
      case EnumQuestionType.MULTI_CHOICE:
        data.options = question.multi.options;
        break;
      case EnumQuestionType.SINGLE_CHOICE:
        data.answer = question.single.answer;
        data.options = question.single.options;
        break;
      default:
        console.log('Type not found');
        return;
    }
    console.log('data2', data);
    // data.type = "CLOSE";
    QuestionService.addQuestion(data, path)
      .then((response) => {
        setIsLoading(false);
        enqueueSnackbar(formatMessage({ id: 'success_recorded_question' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
        console.log(response);
        const formatData = {
          type: 'add',
          data: response.data.data
        };
        publishEvent('getNewQuestion', formatData);
        publishEvent('clear-add-form', {});
        // dispatch(resetData({ type: data.type }));
      })
      .catch((err) => {
        enqueueSnackbar(formatMessage({ id: 'error_occur' }), {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });

        setIsLoading(false);
        console.log('err?.response', err?.response);
        const errors = [];
        // console.log(err.response.data.code);
        switch (err?.response?.data?.code) {
          case 'VALIDATION_ERROR':
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'content'))
              errors.push({
                code: 'content',
                message: formatMessage({
                  id: 'question_content_must_not_be_empty'
                })
              });
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'answer'))
              errors.push({
                code: 'answer',
                message: formatMessage({ id: 'answer_most_not_be_empty' })
              });
            if (
              Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'explanation')
            )
              errors.push({
                code: 'explanation',
                message: 'Le champ explication est requis pour cette question'
              });
            if (
              Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'answerTags')
            )
              errors.push({
                code: 'answerTags',
                message: formatMessage({ id: 'you_most_enter_At_list_2_tags' })
              });
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'images'))
              errors.push({
                code: 'images',
                message: formatMessage({ id: 'it_must_be_images' })
              });
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'options'))
              errors.push({
                code: 'options',
                message: formatMessage({ id: 'it_must_be_images' })
              });
            break;
          case 'EXPLANATION_ERROR':
            errors.push({
              code: 'explanation',
              message: 'Le Champ explication est requis pour les réponses juste'
            });
            break;
          case 'PARENT_ERROR':
            console.log(err);
            break;
          case 'MULTI_OPTIONS_LENGTH_ERROR':
            errors.push({
              code: 'MULTI_OPTIONS_LENGTH_ERROR',
              message: formatMessage({
                id: 'options_must_have_at_least_two_valid_values'
              })
            });
            break;
          case 'TYPE_QUESTION_NOT_FOUND':
            errors.push({
              code: 'TYPE_QUESTION_NOT_FOUND',
              message: formatMessage({
                id: 'the_type_of_this_question_does_not_exist'
              })
            });
            break;
          case 'SAVE_QUESTION_ERROR':
            errors.push({
              code: 'SAVE_QUESTION_ERROR',
              message: formatMessage({ id: 'save_not_complet' })
            });
            break;
          default:
            console.log('Type not found');
            return;
        }
        console.log('errors = ', errors);
        setErrorMessages(errors);
      });
  };

  return (
    <Paper elevation={0} sx={{ p: '10px', mt: 1 }}>
      <div className="layout horizontal center">
        <div className="flex">
          <AppTitle
            icon={<AddCircleOutlineOutlined />}
            title={formatMessage({ id: 'add_question' })}
            subtitle={formatMessage({ id: 'fill_all_informations' })}
          />
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        {/* errorMessages.length > 0 && (
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <ul>
              {errorMessages.map((e) => (
                <li>{e}</li>
              ))}
            </ul>
          </div>
              )*/}
        <QuestionAddGeneric
          metadata={props.metadata}
          submitting={isLoading}
          errorMessages={errorMessages}
          version="CURRENT"
        />
        {question.type === EnumQuestionType.OPEN && (
          <QuestionAddOpen submitting={isLoading} errorMessages={errorMessages} />
        )}
        {question.type === EnumQuestionType.MULTI_CHOICE && (
          <QuestionAddMulti submitting={isLoading} errorMessages={errorMessages} />
        )}
        {question.type === EnumQuestionType.SINGLE_CHOICE && (
          <QuestionAddSingle submitting={isLoading} errorMessages={errorMessages} />
        )}
      </div>
      <div style={{ marginTop: '10px' }} className="layout horizontal center end-justified">
        <span style={{ paddingRight: '10px' }}>
          {formatMessage({ id: 'submit_as' })} &nbsp;
          {question.type === EnumQuestionType.OPEN && (
            <b> {formatMessage({ id: 'open_answer_question' })}</b>
          )}
          {question.type === EnumQuestionType.MULTI_CHOICE && (
            <b> {formatMessage({ id: 'MCQ' })}</b>
          )}
          {question.type === EnumQuestionType.SINGLE_CHOICE && (
            <b> {formatMessage({ id: 'single_choice' })}</b>
          )}
        </span>
        <span>
          <LoadingButton
            loading={isLoading}
            size="large"
            loadingPosition="start"
            variant="contained"
            color="primary"
            type="submit"
            onClick={doSubmitForm}
            startIcon={<Send />}
            sx={{
              fontWeight: 'bold',
              textTransform: 'capitalize'
            }}>
            {isLoading ? formatMessage({ id: 'in_progress' }) : formatMessage({ id: 'save' })}
          </LoadingButton>
        </span>
      </div>
    </Paper>
  );
}

export default QuestionAdd;
