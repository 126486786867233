import axios from 'axios';
import cookieService from '../../../cookieService';
import KEYS from '../../../utils/key';

const authHeader = () => {
  const token = cookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      'x-access-token': token,
      'Content-Type': 'application/json'
    };
  } else {
    return {};
  }
};
const viewQuestion = (body) => {
  console.log(body.questionId);
  // const url = KEYS.backendPath + '/quiz/question/view/' + encodeURIComponent(body.questionId);
  const url = KEYS.backendPath + '/quiz/question/view';
  const headers = authHeader();
  // headers["x-resource"] = btoa(path);
  // /console.log(body);
  const params = {
    version: body.questionId.version,
    questionId: encodeURIComponent(body.questionId.questionId)
  };
  return axios.get(url, { params, headers: headers });
};

const getSubQuestion = (question, path) => {
  const url = KEYS.backendPath + '/quiz/question/subQuestion/';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(path);
  const params = {
    version: question.version,
    questionId: question.questionId
  };
  return axios.get(url, { params: params, headers: headers });
};

const SharedQuestionService = {
  viewQuestion,
  getSubQuestion
};

export default SharedQuestionService;
