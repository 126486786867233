import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

import { useDispatch, useSelector } from 'react-redux';
import { selectOpen, setOpen } from '../../../../../stores/slices/subQuestionSlice';
import { useIntl } from 'react-intl';

function QuestionAddOpen(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const open = useSelector(selectOpen);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    answer: open?.answer ?? '',
    explanation: open?.explanation ?? '',
    answerTags: open?.answerTags ?? []
  });
  useEffect(() => {
    function handleClearAddFormEvent(event) {
      setValues({
        answer: '',
        explanation: '',
        answerTags: []
      });
    }

    document.addEventListener('clear-add-sub-form', handleClearAddFormEvent);
    return () => {
      document.removeEventListener('clear-add-sub-form', handleClearAddFormEvent);
    };
  }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeTags = (value) => {
    setValues({ ...values, ['answerTags']: value });
  };

  useEffect(() => {
    dispatch(setOpen(JSON.parse(JSON.stringify(values))));
  }, [values]);

  useEffect(() => {
    // console.log("Props open changed");
  }, [props]);
  function getError(errors, code) {
    const error = errors.find((err) => err.code === code);
    return error;
  }
  return (
    <>
      <div className="layout horizontal">
        <div style={{ marginTop: '10px' }} className="layout vertical flex-7">
          {props.errorMessages.length > 0 && (
            <div className="error" style={{ color: 'red' }}>
              {getError(props.errorMessages, 'answer') &&
                getError(props.errorMessages, 'answer').message}
            </div>
          )}
          <TextField
            disabled={props.submitting}
            size="small"
            label={formatMessage({ id: 'answer_of_the_question' })}
            multiline
            maxRows={4}
            value={values.answer}
            sx={{ mb: 1.5 }}
            onChange={handleChange('answer')}
          />
          {props.errorMessages.length > 0 && (
            <div className="error" style={{ color: 'red' }}>
              {getError(props.errorMessages, 'answerTags') &&
                getError(props.errorMessages, 'answerTags').message}
            </div>
          )}
          <Autocomplete
            disabled={props.submitting}
            multiple
            size="small"
            id="tags-filled"
            options={[]}
            defaultValue={[]}
            value={values.answerTags}
            // onChange={handleChange('answerTags')}
            onChange={(event, newValue, reason) => {
              if (
                event.type === 'keydown' &&
                event.key === 'Backspace' &&
                reason === 'removeOption'
              ) {
                return;
              }
              handleChangeTags(newValue);
              // setAnswerTags(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={formatMessage({ id: 'enter_each_tags_press_enter' })}
                placeholder={formatMessage({ id: 'enter_each_tags_press_enter' })}
              />
            )}
          />
          {props.errorMessages.length > 0 && (
            <div className="error" style={{ color: 'red' }}>
              {getError(props.errorMessages, 'explanation') &&
                getError(props.errorMessages, 'explanation').message}
            </div>
          )}
          <TextField
            disabled={props.submitting}
            size="small"
            label={formatMessage({ id: 'explanation' })}
            multiline
            maxRows={4}
            value={values.explanation}
            sx={{ mt: 1.5 }}
            onChange={handleChange('explanation')}
          />
        </div>
        <div className="flex-5"></div>
      </div>
    </>
  );
}

export default QuestionAddOpen;
