import { Route, Routes } from 'react-router-dom';
import Page404 from '../../components/shared/Page404';
import LoginPage from './pages/LoginPage';

function UserModule() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Page404 source={{ type: 'unknow', content: 'User/...' }} />} />
    </Routes>
  );
}

export default UserModule;
