import { QuestionMark } from '@mui/icons-material';
import { Box, Paper, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import AppTitle from '../../../../../components/shared/AppTitle';
import QuestionService from '../../../services/question-service';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AppProgress from '../../../../../components/shared/AppProgress';

import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import { itemToData } from 'dynamo-converters';
import QuestionItem from './QuestionItem';
import { convertTopicPathToXressource } from '../../../../../utils/Utils';
import { useSnackbar } from 'notistack';

import { useDispatch } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import { useIntl } from 'react-intl';
import QuestionNotifList from './QuestionNotifList';

const LINEAR_PROGRESS = 2;
const CIRCULAR_PROGRESS = 1;

function QuestionList(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [isLoading, setIsLoading] = useState(0);

  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [path, setPath] = useState('');
  const [topicPath, setTopicPath] = useState('');
  const [questions, setQuestions] = useState([]);
  const [pages, setPages] = useState(['']);
  const [currentPage, setCurrentPage] = useState(1);

  const [defaultLimit, setDefaultLimit] = useState(10);
  const [limit, setLimit] = useState(10);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispath = useDispatch();
  const theme = useTheme();

  // //const fullScreen = true;
  // const usePrevious = (value) => {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // };
  // const useHasChanged = (val) => {
  //   const prevVal = usePrevious(val);
  //   return prevVal !== val;
  // };
  // const hasChangedMetadatas = useHasChanged(props.metadata);

  useEffect(() => {
    handleSearch();
  }, [props.metadata, props]);

  const reset = () => {
    setError('');
    setQuestions([]);
    setPages(['']);
    setCurrentPage(1);
    setLimit(defaultLimit);
  };

  const handleSearch = () => {
    console.log('param', props);
    if (props.params != null && props.params.topicPath) {
      // si le topiPath ou le parametre de recherche à changé,alors on reinitialise la recherche
      reset();
      setIsLoading(CIRCULAR_PROGRESS);
    }

    listQuestion({ ...props.params, limit });
  };

  const handleChangePage = (event, value) => {
    console.log('handleChangePage', value);
    setCurrentPage(value);
    listQuestion({ ...props.params, limit, lastEvaluatedKeyPrev: pages.at(value - 1) });
  };

  function listQuestion(params) {
    console.log('params', params);
    console.log('props.params', props.params);
    setIsLoading(pages.length > 1 ? LINEAR_PROGRESS : CIRCULAR_PROGRESS);
    // params = { ...params, archive: 0, notif: 0 }
    // params = params;
    // params.notifAuthor = "billy"
    // params.version = params.notif === "1" ? "NOTIF" : "CURRENT";
    QuestionService.listAdminQuestions(params, params.isTeacherNotif)
      .then((response) => {
        console.log('Latest pages', pages);
        console.log(response.data);
        setIsLoading(0);
        if (
          response.data.questions != null &&
          Array.isArray(response.data.questions) &&
          response.data.questions.length > 0
        ) {
          console.log('Setting question ....');
          setQuestions(response.data.questions.map((question) => itemToData(question)));
          const lastEvaluatedKeyNext = response.data.lastEvaluatedKeyNext;
          if (
            lastEvaluatedKeyNext !== null &&
            lastEvaluatedKeyNext !== '' &&
            pages.indexOf(lastEvaluatedKeyNext) < 0
          ) {
            setPages((oldArray) => [...oldArray, lastEvaluatedKeyNext]);
          }
        } else {
          // if(props.params!=null) setQuestions([]);
          if (
            params.lastEvaluatedKeyPrev &&
            params.lastEvaluatedKeyPrev != null &&
            params.lastEvaluatedKeyPrev !== ''
          ) {
            // La page n'a par envoyé de données, donc on retirer cette page de la liste.
            setPages((oldArray) => oldArray.filter((val) => val !== params.lastEvaluatedKeyPrev));
            setCurrentPage(currentPage - 1);
          }
        }
      })
      .catch((err) => {
        setIsLoading(0);
        // setQuestions([]);
        console.log('List data error');
      });
  }

  const doUnArchive = (index, question) => {
    console.log('doUnArchive question', index, question);
    const path = convertTopicPathToXressource(question.topicPath);
    console.log('Path', path);
    // setIsLoading(true);
    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });
    QuestionService.unArchiveQuestion(question.questionId, question.version, path)
      .then((response) => {
        console.log(response);
        const newQuestions = questions.filter((item) => item.questionId !== question.questionId);
        setQuestions(newQuestions);
        enqueueSnackbar(formatMessage({ id: 'archive_success' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'unkown_error' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
  };

  const doDelete = (index, question) => {
    console.log('doDelete question', index, question);
    const path = convertTopicPathToXressource(question.topicPath);
    console.log('Path', path);
    // setIsLoading(true);
    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });
    QuestionService.deleteQuestion(question.questionId, question.version, path)
      .then((response) => {
        console.log(response);
        const newQuestions = questions.filter((item) => item.questionId !== question.questionId);
        setQuestions(newQuestions);
        enqueueSnackbar(formatMessage({ id: 'question_deleted_successfully' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question_and_status' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'unkown_error' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
  };

  return (
    <>
      <Paper elevation={0} sx={{ p: '10px' }}>
        <AppTitle
          icon={<QuestionMark />}
          title={props.params.title}
          subtitle={props.params.subtitle}
        />

        {/* Juste pour creer un espacement vertical de 15 pixels */}
        <Box sx={{ width: '100%', padding: '15px 0' }}> </Box>

        {isLoading === LINEAR_PROGRESS && (
          <Box sx={{ width: '100%', padding: '15px 0' }}>
            <LinearProgress />
          </Box>
        )}
        {isLoading === CIRCULAR_PROGRESS && <AppProgress />}
        {isLoading !== CIRCULAR_PROGRESS && (
          <div>
            <div>
              <List
                sx={{
                  width: '100%',
                  pt: '0px',
                  pb: '0px',
                  bgcolor: 'background.paper'
                }}>
                {questions.map((question, index) => (
                  <div key={index}>
                    <QuestionItem
                      parentProps={props}
                      index={index}
                      question={question}
                      onUnArchive={() => doUnArchive(index, question)}
                      onDelete={() => doDelete(index, question)}
                    />
                    <Divider sx={{ marginLeft: '48px' }} variant="inset" component="li" />
                    {question.version === 'NOTIF' && (
                      <>
                        <QuestionNotifList
                          // idFrom={question.questionId}
                          idFrom={question}
                          isTeacher={props.params.isTeacherNotif}
                          families={question.families.map((question) => {
                            return itemToData(question);
                          })}
                        />
                      </>
                    )}
                  </div>
                ))}
                {questions.length === 0 && (
                  <div
                    style={{
                      padding: '50px',
                      backgroundColor: '#F9F9F9',
                      borderRadius: '60px',
                      margin: '20px 70px'
                    }}
                    className="layout vertical center-center">
                    <div
                      style={{
                        padding: '15px',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}>
                      OUPS !!!
                    </div>
                    <div
                      style={{
                        fontSize: '17px',
                        fontWeight: '600',
                        width: '75%',
                        color: 'var(--paper-grey-900)'
                      }}
                      className="layout horizontal center-center">
                      {formatMessage({ id: 'no_question_found_in_topic' })}
                    </div>
                  </div>
                )}
              </List>
            </div>
            {questions.length > 0 && pages.length > 0 && (
              <div className="layout horizontal center end-justified">
                <Stack spacing={2}>
                  <Pagination
                    color="primary"
                    showFirstButton
                    showLastButton
                    count={pages.length}
                    page={currentPage}
                    onChange={handleChangePage}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </div>
            )}
          </div>
        )}
      </Paper>
    </>
  );
}

export default QuestionList;
