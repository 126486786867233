import { QuestionMark } from '@mui/icons-material';
import { Box, Dialog, DialogContent, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AppTitle from '../../../../../components/shared/AppTitle';
import QuestionService from '../../../services/question-service';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AppProgress from '../../../../../components/shared/AppProgress';

import List from '@mui/material/List';

// import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';
import { itemToData } from 'dynamo-converters';
import QuestionItem from './QuestionItem';
import { convertTopicPathToXressource } from '../../../../../utils/Utils';
import { useSnackbar } from 'notistack';
import QuestionEdit from '../edit/QuestionEdit';
import {
  setOpen,
  setGeneric,
  setSingle,
  resetData,
  setMulti
} from '../../../../../stores/slices/editQuestionSlice';
import { useDispatch } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import { useIntl } from 'react-intl';
import EnumQuestionType from '../../../../../utils/EnumQuestionType';

const LINEAR_PROGRESS = 2;
const CIRCULAR_PROGRESS = 1;

function QuestionList(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [isLoading, setIsLoading] = useState(0);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowAdd, setIsShowAdd] = useState(false);

  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [questions, setQuestions] = useState([]);
  const [newQuestions, setNewQuestions] = useState([]);
  const [pages, setPages] = useState(['']);
  const [questionIndex, setSelectQuestionIndex] = useState(null);
  const [questionParent, setQuestionParent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [defaultLimit, setDefaultLimit] = useState(10);
  const [limit, setLimit] = useState(10);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispath = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // const fullScreen = true;
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const useHasChanged = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
  };
  const hasChangedMetadatas = useHasChanged(props.metadata);

  useEffect(() => {
    if (hasChangedMetadatas) {
      // quelque chose a changé dans les metadatas
      console.log('haschanged');
      reset();
      const formatData = {
        type: 'import',
        data: []
      };
      publish('getNewQuestion', formatData);
    } else console.log('haschanged false');
    const dataPath = computePath();
    const params = { topicPath: dataPath.topicPath, limit };
    listQuestion(params, dataPath.path);
  }, [props.metadata]);

  const listener = (event) => {
    const questions = newQuestions.map((element) => {
      return element;
    });
    if (event.detail.type === 'import') {
      event.detail.data.forEach((question) => {
        questions.push(question);
      });
    } else if (event.detail.type === 'add') {
      questions.push(event.detail.data);
    }
    console.log(questions);
    setNewQuestions(questions);
  };
  function subscribe(eventName, listener) {
    console.log('La souscription');
    document.addEventListener(eventName, listener);
  }
  function unsubscribe(eventName, listener) {
    console.log('La desouscription');
    document.removeEventListener(eventName, listener);
  }
  function publish(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data });
    console.log(eventName);
    document.dispatchEvent(event);
  }
  useEffect(() => {
    console.log("j'ai souscrit");
    subscribe('getNewQuestion', listener);
    return () => {
      unsubscribe('getNewQuestion', listener);
    };
    // return unsubscribe("importQuestion", listener);
  }, []);

  const reset = () => {
    setError('');
    setQuestions([]);
    setPages(['']);
    setCurrentPage(1);
    setLimit(defaultLimit);
  };
  const handleSearch = (value) => {
    console.log('handleSearch', value);
    setSearch(value);
    reset();
    const dataPath = computePath();
    const params = { search: value, topicPath: dataPath.topicPath, limit };
    listQuestion(params, dataPath.path);
  };
  const computePath = () => {
    const _path = `${props.metadata.country}.${props.metadata.class}.${props.metadata.subject}`;
    const _topicPath = `${props.metadata.country}#${props.metadata.class}#${props.metadata.subject}#${props.metadata.topic}`;
    return { path: _path, topicPath: _topicPath };
  };
  const handleChangePage = (event, value) => {
    const dataPath = computePath();
    console.log('handleChangePage', value);
    setCurrentPage(value);
    const params = {
      topicPath: dataPath.topicPath,
      limit,
      lastEvaluatedKeyPrev: pages.at(value - 1)
    };
    if (search !== null && search.trim() !== '') params.search = search;
    listQuestion(params, dataPath.path);
  };
  const handleCloseEdit = (newQuestion) => {
    console.log('Handle closes Edit', newQuestion);
    setIsShowEdit(false);
    if (newQuestion && newQuestion !== null && questionIndex != null) {
      const datas = [...questions];
      datas[parseInt(questionIndex)] = newQuestion;
      setQuestions(datas);
    }
    setSelectQuestionIndex(null);
    setTimeout(() => dispath(resetData()), 500);
  };
  const handleCloseAdd = (newQuestion) => {
    console.log('Handle closes Edit', newQuestion);
    setIsShowAdd(false);
    if (newQuestion && newQuestion !== null && questionIndex != null) {
      const datas = [...questions];
      datas[parseInt(questionIndex)] = newQuestion;
      setQuestions(datas);
    }
    setQuestionParent(null);
    setTimeout(() => dispath(resetData()), 500);
  };

  function listQuestion(params, path) {
    setIsLoading(pages.length > 1 ? LINEAR_PROGRESS : CIRCULAR_PROGRESS);
    params = { ...params, archive: 0 };
    params.version = 'CURRENT'; // Uniquement les currents
    QuestionService.listQuestions(params, path)
      .then((response) => {
        console.log('Latest pages', pages);
        console.log(response.data);
        setIsLoading(0);
        if (
          response.data.questions != null &&
          Array.isArray(response.data.questions) &&
          response.data.questions.length > 0
        ) {
          setQuestions(response.data.questions.map((question) => itemToData(question)));
          console.log(response.data.questions);
          const lastEvaluatedKeyNext = response.data.lastEvaluatedKeyNext;
          if (
            lastEvaluatedKeyNext !== null &&
            lastEvaluatedKeyNext !== '' &&
            pages.indexOf(lastEvaluatedKeyNext) < 0
          ) {
            setPages((oldArray) => [...oldArray, lastEvaluatedKeyNext]);
          }
        } else {
          if (
            params.lastEvaluatedKeyPrev &&
            params.lastEvaluatedKeyPrev != null &&
            params.lastEvaluatedKeyPrev !== ''
          ) {
            // La page n'a par envoyé de données, donc on retirer cette page de la liste.
            setPages((oldArray) => oldArray.filter((val) => val !== params.lastEvaluatedKeyPrev));
            setCurrentPage(currentPage - 1);
          }
        }
      })
      .catch((err) => {
        setIsLoading(0);
        // setQuestions([]);
        console.log('List data error');
      });
  }
  // console.log("pages",pages);
  const doEdit = (index, question) => {
    dispath(resetData());
    const genericData = (({ answer, options, answerTags, ...o }) => o)(question);
    console.log('Generic data', genericData);
    dispath(setGeneric(genericData));
    switch (question.type) {
      case EnumQuestionType.OPEN:
        dispath(
          setOpen({
            answer: question.answer,
            answerTags: question.answerTags,
            explanation: question.explanation
          })
        );
        break;
      case EnumQuestionType.MULTI_CHOICE:
        dispath(setMulti({ options: JSON.parse(JSON.stringify(question.options)) }));
        break;
      case EnumQuestionType.SINGLE_CHOICE:
        dispath(
          setSingle({
            options: JSON.parse(JSON.stringify(question.options)),
            answer: question.answer
          })
        );
        break;
    }
    console.log('doEdit/genericData = ', genericData);
    setIsShowEdit(true);
    setSelectQuestionIndex(index);
  };
  const doAddSubQuestion = (index, question) => {
    /* dispath(resetData());
    const genericData = (({ answer, options, answerTags, ...o }) => o)(question);
    console.log('Generic data', genericData);
    dispath(setGeneric(genericData));
    switch (question.type) {
      case EnumQuestionType.OPEN:
        dispath(setOpen({ answer: question.answer, answerTags: question.answerTags }));
        break;
      case EnumQuestionType.MULTI_CHOICE:
        dispath(setMulti({ options: JSON.parse(JSON.stringify(question.options)) }));
        break;
      case EnumQuestionType.SINGLE_CHOICE:
        dispath(
          setSingle({
            options: JSON.parse(JSON.stringify(question.options)),
            answer: question.answer
          })
        );
        break;
    } */
    setIsShowAdd(true);
    setQuestionParent(question);
  };
  const doArchive = (index, question) => {
    console.log('doArchive question', index, question);
    const path = convertTopicPathToXressource(question.topicPath);
    console.log('Path', path);
    // setIsLoading(true);
    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });
    QuestionService.archiveQuestion(question.questionId, question.version, path)
      .then((response) => {
        console.log(response);
        const newQuestions = questions.filter((item) => item.questionId !== question.questionId);
        setQuestions(newQuestions);
        enqueueSnackbar(formatMessage({ id: 'archive_success' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar('Oupps!! vous devez renseigner la question', {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'error_occur' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
  };
  const doChangeStatus = (newStatus, index, question) => {
    // TODO: terminer l'implémentation
    console.log('doChangeStatus question, newStatus =', newStatus, index, question);
    const query = {
      version: question.version,
      questionId: question.questionId,
      status: newStatus
    };
    const path = convertTopicPathToXressource(question.topicPath);
    // setIsLoading(true);
    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });

    QuestionService.setStatusQuestion(query, path)
      .then((response) => {
        closeSnackbar(queueSnack);
        enqueueSnackbar(
          newStatus === 1
            ? formatMessage({ id: 'question_activated_successfully' })
            : formatMessage({ id: 'question_unactivated_successfully' }),
          {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          }
        );

        const newQuestions = questions.map((item) => {
          if (item.questionId === question.questionId)
            item.enabled = parseInt(newStatus) === 0 ? false : true;
          return item;
        });
        setQuestions(newQuestions);
        // setIsLoading(false);
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (err?.response?.data?.code) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question_and_status' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_VALUE':
            enqueueSnackbar(formatMessage({ id: 'status_values' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;

          default:
            enqueueSnackbar(formatMessage({ id: 'error_occur' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
        // setHasError(true);
        // setIsLoading(false);
      });
  };
  return (
    <>
      <Paper elevation={0} sx={{ p: '10px' }} id="questions-list">
        <AppTitle
          icon={<QuestionMark />}
          title={formatMessage({ id: 'question_of_theme' })}
          subtitle={formatMessage({ id: 'see_all_question_of_theme' })}
        />
        {newQuestions.length > 0 && (
          <Box sx={{ paddingTop: '20px' }}>
            <AppTitle
              icon={<QuestionMark />}
              title={formatMessage({ id: 'new_question' })}
              subtitle={formatMessage({ id: 'list_new_questions' })}
            />
            <div>
              <List
                sx={{
                  width: '100%',
                  pt: '10px',
                  pb: '10px',
                  bgcolor: 'background.paper'
                }}>
                {newQuestions.map((question, index) => (
                  <div key={index}>
                    <QuestionItem
                      index={index}
                      question={question}
                      onEdit={(e) => doEdit(index, question)}
                      onArchive={() => doArchive(index, question)}
                      onChangeStatus={(newStatus) => doChangeStatus(newStatus, index, question)}
                      metadata={props.metadata}
                    />
                    <Divider sx={{ marginLeft: '48px' }} variant="inset" component="li" />
                  </div>
                ))}
              </List>
            </div>
          </Box>
        )}
        <div className="layout horizontal end-justified" style={{ padding: '10px 0 5px 0' }}>
          <CustomizedInputBase onSearch={handleSearch} />
        </div>

        {isLoading === LINEAR_PROGRESS && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {isLoading === CIRCULAR_PROGRESS && <AppProgress />}
        {isLoading !== CIRCULAR_PROGRESS && (
          <div>
            {newQuestions.length > 0 && (
              <AppTitle
                icon={<QuestionMark />}
                title={formatMessage({ id: 'list_of_questions' })}
                subtitle={formatMessage({ id: 'found_all_your_questions' })}
                sx={{ mt: 2 }}
              />
            )}
            <div>
              <List
                sx={{
                  width: '100%',
                  pt: '10px',
                  pb: '0px',
                  bgcolor: 'background.paper'
                }}>
                {questions.map((question, index) => (
                  <div key={index}>
                    <QuestionItem
                      index={index}
                      question={question}
                      onEdit={() => doEdit(index, question)}
                      onArchive={() => doArchive(index, question)}
                      onChangeStatus={(newStatus) => doChangeStatus(newStatus, index, question)}
                      metadata={props.metadata}
                    />
                    <Divider sx={{ marginLeft: '48px' }} variant="inset" component="li" />
                  </div>
                ))}
                {questions.length === 0 && (
                  <div
                    style={{
                      padding: '50px',
                      backgroundColor: '#F9F9F9',
                      borderRadius: '60px',
                      margin: '20px 70px'
                    }}
                    className="layout vertical center-center">
                    <div
                      style={{
                        padding: '15px',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}>
                      OUPS !!!
                    </div>
                    <div
                      style={{
                        fontSize: '17px',
                        fontWeight: '600',
                        width: '75%',
                        color: 'var(--paper-grey-900)'
                      }}
                      className="layout horizontal center-center">
                      {formatMessage({ id: 'no_question_found_in_topic' })}
                    </div>
                  </div>
                )}
              </List>
            </div>
            {questions.length > 0 && pages.length > 0 && (
              <div
                style={{ paddingTop: '10px' }}
                className="layout horizontal center end-justified">
                <Stack spacing={2}>
                  <Pagination
                    color="primary"
                    showFirstButton
                    showLastButton
                    count={pages.length}
                    page={currentPage}
                    onChange={handleChangePage}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </div>
            )}
          </div>
        )}
      </Paper>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={isShowEdit}
        onClose={(event) => handleCloseEdit(null)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              // maxWidth: '750px' // Set your width here
            }
          }
        }}>
        <DialogContent>
          <QuestionEdit onCloseEdit={(newQuestion) => handleCloseEdit(newQuestion)} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default QuestionList;

function CustomizedInputBase(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [search, setSearch] = useState('');

  const doSearch = (event) => {
    if (props.onSearch) {
      console.log('Props onsearch', search);
      props.onSearch(search);
    }
  };
  const onKeyUp = (e) => {
    const enterKey = 13; // Key Code for Enter Key
    if (e.which == enterKey) {
      if (props.onSearch) {
        props.onSearch(search);
      }
    }
  };
  return (
    <>
      {/* <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          onKeyUp={onKeyUp}
          type="text"
          onChange={(event) => setSearch(event.target.value)}
          value={search}
          placeholder={formatMessage({ id: 'search_question' })}
          inputProps={{ 'aria-label': formatMessage({ id: 'search_question' }) }}
        />

        <IconButton onClick={doSearch} type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper> */}
    </>
  );
}
