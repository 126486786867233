const toLowerCase = (str) => str.toLowerCase();
const QUESTION_TYPE_COLOR = {
  OPEN: 'success',
  MULTI_CHOICE: 'primary',
  SINGLE_CHOICE: 'secondary'
};

const cancelEventPropagation = (event) => {
  if (event == null) return;
  event.preventDefault();
  if (event.stopPropagation) {
    event.stopPropagation();
  } else if (window.event) {
    window.event.cancelBubble = true;
  }
};

const computeXAccessRessource = (path) => {
  let tPath = path.split('\\');
  tPath = tPath.slice(0);
  tPath = tPath.slice(-1);
  const realPath = tPath.join('.').toLowerCase();
  console.log('x-access-ressouce:', realPath);
  return realPath;
};

const convertTopicPathToXressource = (text) => text.split('#').slice(0, -1).join('.');
const convertTopicPathToMetadata = (text) => {
  const data = text.split('#');
  return { country: data.at(0), class: data.at(1), subject: data.at(2), topic: data.at(3) };
};

const publishEvent = (eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data });
  console.log(eventName);
  document.dispatchEvent(event);
};
export {
  QUESTION_TYPE_COLOR,
  toLowerCase,
  cancelEventPropagation,
  computeXAccessRessource,
  convertTopicPathToXressource,
  convertTopicPathToMetadata,
  publishEvent
};
