import { Add, RemoveCircle } from '@mui/icons-material';
import { IconButton, Typography, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch, useSelector } from 'react-redux';
import { selectSingle, setSingle } from '../../../../../stores/slices/subQuestionSlice';
import { useIntl } from 'react-intl';

function QuestionAddSingle(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const single = useSelector(selectSingle);
  const dispatch = useDispatch();
  const initialDatas = JSON.parse(JSON.stringify(single?.options ?? [])) ?? [];

  while (initialDatas.length < 2) {
    initialDatas.push({
      content: '',
      explanation: ''
    });
  }
  const [options, setOptions] = useState(initialDatas);
  const [answer, setAnswer] = useState(single?.answer ?? 0);
  useEffect(() => {
    function handleClearAddFormEvent(event) {
      setOptions([
        {
          content: '',
          explanation: ''
        },
        {
          content: '',
          explanation: ''
        }
      ]);
      setAnswer(0);
    }

    document.addEventListener('clear-add-sub-form', handleClearAddFormEvent);
    return () => {
      document.removeEventListener('clear-add-sub-form', handleClearAddFormEvent);
    };
  }, []);
  useEffect(() => {
    dispatch(
      setSingle({
        options: JSON.parse(JSON.stringify(options)),
        answer: answer
      })
    );
  }, [options, answer]);

  const doAddOption = (event) => {
    setOptions([
      ...options,
      {
        // isCorrect: false,
        content: '',
        explanation: ''
      }
    ]);
  };
  const updateCheck = (event, index) => {
    setAnswer(index);
  };
  const updateText = (event, index) => {
    const data = [...options];
    data[index]['content'] = event.target.value;
    setOptions(data);
  };
  const updateExplanation = (event, index) => {
    const data = [...options];
    data[index]['explanation'] = event.target.value;
    setOptions(data);
  };
  const removeFields = (index) => {
    const data = [...options];
    data.splice(index, 1);
    setOptions(data);
    setAnswer(0);
  };

  useEffect(() => {
    console.log('Props single changed');
  }, [props]);
  function getError(errors, code) {
    const error = errors.find((err) => err.code === code);
    return error;
  }
  return (
    <>
      <div style={{ marginTop: '10px' }} className="layout vertical">
        {props.errorMessages.length > 0 && (
          <div className="error" style={{ color: 'red' }}>
            {getError(props.errorMessages, 'options') &&
              getError(props.errorMessages, 'options').message}
            {getError(props.errorMessages, 'explanation') &&
              getError(props.errorMessages, 'explanation').message}
          </div>
        )}
        <div className="layout horizontal">
          <FormControl
            className="flex-9"
            disabled={props.submitting}
            sx={{}}
            component="fieldset"
            variant="standard">
            <FormLabel component="legend">
              {formatMessage({ id: 'answer_option' })}(
              <small>{formatMessage({ id: 'check_true_answer' })}</small>)
            </FormLabel>
            <FormGroup>
              {options.map((option, index) => (
                <div key={index} className="layout horizontal center">
                  <FormControlLabel
                    className="flex"
                    sx={{
                      mt: 1.5,
                      '.MuiFormControlLabel-label': {
                        flex: '1 1',
                        flexBasis: '1e-9px'
                      },
                      '.MuiFormControlLabel-label > div': {
                        width: '100%'
                      }
                    }}
                    control={
                      <Radio
                        checked={index === answer}
                        onChange={(event) => updateCheck(event, index)}
                        name={'option' + (index + 1)}
                      />
                    }
                    label={
                      <Stack spacing={2} direction="row">
                        <TextField
                          disabled={props.submitting}
                          size="small"
                          value={option.content}
                          onChange={(event) => updateText(event, index)}
                          label={formatMessage({ id: 'response_option' }) + (index + 1)}
                          placeholder={
                            formatMessage({ id: 'enter_the_response_option' }) + (index + 1)
                          }
                          sx={{ width: '100%' }}
                        />
                        <TextField
                          disabled={props.submitting}
                          size="small"
                          value={option.explanation}
                          onChange={(event) => updateExplanation(event, index)}
                          label={formatMessage({ id: 'explanation_answer' }) + (index + 1)}
                          placeholder={
                            formatMessage({ id: 'enter_explanation_for_the_option' }) + (index + 1)
                          }
                          sx={{ width: '100%' }}
                        />
                      </Stack>
                    }
                  />
                  {options.length > 2 && (
                    <IconButton
                      disabled={props.submitting}
                      onClick={() => removeFields(index)}
                      color="secondary"
                      title={formatMessage({ id: 'remove_option' })}
                      aria-label={formatMessage({ id: 'remove_option' })}>
                      <RemoveCircle />
                    </IconButton>
                  )}
                </div>
              ))}
            </FormGroup>
            <FormHelperText>
              {formatMessage({ id: 'true_option' })}: {answer + 1}
            </FormHelperText>
          </FormControl>
          <div className="flex-3"></div>
        </div>
        <div className="layout horizontal center">
          <Typography variant="button" title={formatMessage({ id: 'add_an_option_at_the_end' })}>
            {formatMessage({ id: 'add_an_option' })}
          </Typography>
          <IconButton
            disabled={props.submitting}
            onClick={doAddOption}
            color="secondary"
            aria-label={formatMessage({ id: 'add_an_option' })}>
            <Add />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default QuestionAddSingle;
