import axios from 'axios';
import KEYS from '../../../utils/key';
import { Component } from 'react';
import CookieService from '../../../cookieService';
class AuthService extends Component {
  login = (credentilals) => {
    const url = KEYS.backendPath + '/quiz/auth/signin';
    return axios.post(url, credentilals).then((response) => {
      return response.data;
    });
  };
  checkConnected = () => {
    const url = KEYS.backendPath + '/quiz/auth/is-connected';
    return axios.get(url, { headers: this.authHeader() }).then((response) => {
      return response.data;
    });
  };
  authHeader() {
    const token = CookieService.getToken();
    if (token) {
      // for Node.js Express back-end
      return { 'x-access-token': token };
    } else {
      return {};
    }
  }
}
export default new AuthService();
