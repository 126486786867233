import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';

const AppProgress = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div
      style={{ padding: '50px', backgroundColor: '#FFF' }}
      className="layout vertical center-center">
      <div style={{ padding: '15px' }}>
        <CircularProgressStyled thickness={1} size={100} />
      </div>
      <ProgressDiv className="layout horizontal center-center">
        {formatMessage({ id: 'loading' })}
      </ProgressDiv>
    </div>
  );
};
export default AppProgress;

const CircularProgressStyled = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: 'auto'
}));
const ProgressDiv = styled('div')(({ theme }) => ({
  fontSize: '17px',
  fontWeight: '600',
  width: '75%',
  color: 'var(--paper-grey-900)'
}));
