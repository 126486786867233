import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AuthService from '../services/authservice';
import CookieService from '../../../cookieService';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../stores/slices/userSlice';
import { useIntl } from 'react-intl';
import { useCookies } from 'react-cookie';
import { setCon } from '../../../stores/slices/connectionSlice';

function LoginPage() {
  const AUTH_ERROR = 'AUTH_ERROR';
  const AUTH_SUCCESS = 'AUTH_SUCCESS';
  const ERR_NETWORK = 'ERR_NETWORK';

  const intl = useIntl();
  const { formatMessage } = intl;

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const queryString = currentLocation.search;
  const sp = new URLSearchParams(queryString);
  const redirectUrl = sp.has('redirect_url') ? sp.get('redirect_url') : '/space';
  const theme = useTheme();
  // const toolbarSize = theme.components.MuiAppBar.defaultProps.sx.height + "px";
  const toolbarSize = theme.custom.toolbarHeight + 'px';

  const { register, handleSubmit } = useForm();
  const [pseudoState, setPseudo] = useState({ pseudo: '' });
  const [passwordState, setPassword] = useState({ password: '' });
  const [messageState, setMessage] = useState({ content: '' });
  const [isLoading, setIsLoading] = useState(false);
  /* eslint-disable-next-line no-unused-vars */
  const [cookies, setCookie] = useCookies(['user']);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (!window.navigator.onLine) {
      setMessage({ content: formatMessage({ id: 'no_internet_connection' }) });
      return;
    }

    const credentilals = {
      pseudo: pseudoState.pseudo,
      password: passwordState.password
    };

    setIsLoading(true);
    setMessage({ content: '' });
    AuthService.login(credentilals).then(
      (data) => {
        setIsLoading({ value: false });
        if (data.code === AUTH_SUCCESS) {
          setCookie('token', data.token, { path: '/' });
          CookieService.saveToken(data.token);
          dispatch(setUser(data.user));
          dispatch(setCon(true));
          console.log(data.user);
          if (data.user.role === 'TEACHER') navigate(redirectUrl, { replace: true });
          else navigate('/admin', { replace: true });
        }
      },
      (error) => {
        setIsLoading(false);
        if (error?.response?.data?.message != null) {
          if (error?.response?.data?.code === AUTH_ERROR)
            setMessage({
              content: formatMessage({ id: 'incorrect_pseudo_or_password' })
            });
          navigate(currentLocation, { replace: true });
        } else if (error.code === ERR_NETWORK) {
          setMessage({
            content: formatMessage({ id: 'cannot_connect_to_server' })
          });
        } else {
          setMessage({ content: formatMessage({ id: 'unkown_error' }) });
        }
      }
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={`calc(100vh - ${toolbarSize})`}>
      <Card sx={{ maxWidth: 400, width: 400, padding: 0 }}>
        <CardHeader
          avatar={
            <Avatar
              alt=""
              src="https://www.learndia.com/images/icons/avatar_2x.png"
              sx={{ width: 64, height: 64 }}
            />
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        />

        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              {messageState?.content?.trim().length > 0 && (
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    heigh: 3,
                    padding: 0,
                    fontSize: 12,
                    marginBottom: 2,
                    bgcolor: '#EEEEEE',
                    color: 'red',
                    paddingLeft: 1,
                    paddingRight: 1
                  }}>
                  {messageState.content}
                </Alert>
              )}

              {isLoading === true && <LinearProgress color="success" />}

              <TextField
                id="filled-basic"
                label={formatMessage({ id: 'pseudo' })}
                {...register('pseudo')}
                variant="standard"
                autoComplete="pseudo"
                onChange={(event) => setPseudo({ pseudo: event.target.value })}
                required
                fullWidth
              />

              <TextField
                id="filled-password-input"
                label={formatMessage({ id: 'password' })}
                type="password"
                {...register('password')}
                autoComplete="current-password"
                variant="standard"
                onChange={(event) => setPassword({ password: event.target.value })}
                required
                fullWidth
              />

              <LoadingButton
                loading={isLoading}
                size="large"
                loadingPosition="center"
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize'
                }}>
                {isLoading
                  ? formatMessage({ id: 'submission' })
                  : formatMessage({ id: 'connection' })}
              </LoadingButton>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default LoginPage;
