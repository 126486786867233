import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import IntlProviderWrapper from './IntlProviderWrapper';
import { Provider } from 'react-redux';
import { store } from './stores/store';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import KEYS from './utils/key';

if (KEYS.LOG_LEVEL === 'DISABLED') {
  console.log = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const styles = {
  success: { backgroundColor: '#43a047' },
  error: { backgroundColor: '#d32f2f' },
  warning: { backgroundColor: '#ffa000' },
  info: { backgroundColor: '#2196f3' }
};

root.render(
  // <React.StrictMode>
  <SnackbarProvider
    classes={{
      variantSuccess: styles.success,
      variantError: styles.error,
      variantWarning: styles.warning,
      variantInfo: styles.info
    }}
    sx={{
      '& .SnackbarContent-root': {
        color: 'black'
      }
    }}>
    <IntlProviderWrapper>
      <CookiesProvider>
        <Provider store={store}>
          <Router>
            <App age={22} />
          </Router>
        </Provider>
      </CookiesProvider>
    </IntlProviderWrapper>
  </SnackbarProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
