import { Box, Card, CardContent, CardHeader, styled, Typography } from '@mui/material';
import React from 'react';
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { useIntl } from 'react-intl';
import { Class, MenuBook, Public, Subject } from '@mui/icons-material';

function Page404(props) {
  const BoxNoDataFound = styled(Box)({
    backgroundColor: 'white',
    padding: '15px',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });
  const intl = useIntl();
  const { formatMessage } = intl;
  const avatar = () => {
    let render = '';
    switch (props.source.type) {
      case 'unknow':
        render = <Avatar aria-label="recipe">404</Avatar>;
        break;
      case 'country_code_not_found':
        render = (
          <Avatar aria-label="recipe">
            <Public />
          </Avatar>
        );
        break;
      case 'select_country':
        render = (
          <Avatar aria-label="recipe">
            <Public />
          </Avatar>
        );
        break;
      case 'topic_code_not_found':
        render = (
          <Avatar aria-label="recipe">
            <MenuBook />
          </Avatar>
        );
        break;
      case 'select_topic':
        render = (
          <Avatar aria-label="recipe">
            <MenuBook />
          </Avatar>
        );
        break;
      case 'subject_code_not_found':
        render = (
          <Avatar aria-label="recipe">
            <Subject />
          </Avatar>
        );
        break;
      case 'select_subject':
        render = (
          <Avatar aria-label="recipe">
            <Subject />
          </Avatar>
        );
        break;
      case 'class_code_not_found':
        render = (
          <Avatar aria-label="recipe">
            <Class />
          </Avatar>
        );
        break;
      case 'select_class':
        render = (
          <Avatar aria-label="recipe">
            <Class />
          </Avatar>
        );
        break;

      default:
        render = <Avatar aria-label="recipe">404</Avatar>;
        break;
    }

    return render;
  };
  return (
    <>
      {props.source.code === '404' && (
        <Card elevation={0} sx={{ padding: '10px' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ backgroundColor: red[500] }} aria-label="recipe">
                404
              </Avatar>
            }
            title={formatMessage({ id: 'page_not_found' })}></CardHeader>

          <CardContent>
            <Typography variant="p">
              <Typography variant="b">{formatMessage({ id: 'source' })}</Typography>
              <Typography variant="b">{props.source.content}</Typography>
            </Typography>
          </CardContent>
        </Card>
      )}
      {props.source.code === '400' && (
        <Card elevation={0}>
          <CardContent>
            <BoxNoDataFound>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Box
                  sx={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    border: '1px solid #F9F9F9',
                    backgroundColor: '#F9F9F9',
                    color: '#c8c8c8',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  {avatar()}
                </Box>
                <div
                  style={{
                    color: '#c8c8c8',
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '5px'
                  }}>
                  <p style={{ lineHeight: '3px', fontWeight: 'bold' }}>
                    {props.source.subtitle
                      ? props.source.subtitle
                      : formatMessage({ id: 'page_not_found' })}
                  </p>
                  <p style={{ fontSize: '13px' }}>{props.source.content}</p>
                </div>
              </Box>
            </BoxNoDataFound>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default Page404;
