import cookie from 'react-cookies';

class CookieService {
  getToken = () => {
    return cookie.load('token');
  };

  saveToken = (token) => {
    cookie.save('token', token, { path: '/' });
  };
}

export default new CookieService();
