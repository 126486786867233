const userLocale = process.env.REACT_APP_USER_LOCALE;
const user = process.env.REACT_APP_USER;
const backendPath = process.env.REACT_APP_BACKEND_PATH;
const CURRENT_COUNTRY = process.env.REACT_APP_CURRENT_COUNTRY;
const RESSOURCE_BASE_PATH = process.env.REACT_APP_S3_RESOURCE_DOMAINE_NAME;
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;

const KEYS = {
  userLocale,
  user,
  backendPath,
  CURRENT_COUNTRY,
  RESSOURCE_BASE_PATH,
  env: process.env.REACT_APP_ENV,
  LOG_LEVEL
};

export default KEYS;
