import React from 'react';
import { useEffect } from 'react';
import QuestionList from '../components/question/list/QuestionList';
import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import FilterData from './../../../components/shared/FilterData';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

function AdminPage(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [showView, setShowView] = useState(true);
  const [tab, setTab] = useState('ALL');

  const [paramsFilter, setParamsFilter] = useState(null);
  // const [topicPath, setTopicPath] = useState("");
  // const [limit, setLimit] = useState(10);

  const handleTab = (event, tab) => {
    setShowView(true);
    setTab(tab);
  };

  const handleSendParams = (params) => {
    console.log(params);
    setParamsFilter(params);
    // setTopicPath(params.topicPath);
    // setParamsFilter(params);
  };

  const handleHide = (event) => {
    setShowView(false);
    setTab('');
  };

  const location = useLocation();

  useEffect(() => {
    console.log('location', location);
    const Tsplit = location.pathname.split('/');
    console.log('split', Tsplit);
    console.log('Taille', Tsplit.length);
    console.log('console.log(window.location)', window.location);
    if (Tsplit.length >= 3) {
      const seletedTap = Tsplit[2].trim().toLocaleUpperCase();
      switch (seletedTap) {
        case 'ALL':
          setTab('ALL');
          break;
        case 'NOTIF':
          setTab('NOTIF');

          break;
        case 'ARCHIVED':
          setTab('ARCHIVED');

          break;
        default:
          setTab('ALL');
      }
    }
  }, []);

  useEffect(() => {}, [formatMessage, paramsFilter, handleSendParams]);

  return (
    <>
      <Paper elevation={0} sx={{ p: '10px' }}>
        <div style={{ padding: '15px 0' }}>
          <FilterData onSetParams={handleSendParams} />
        </div>
      </Paper>
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={0}
          sx={{
            border: (theme) => `0px solid ${theme.palette.divider}`
          }}
          className="layout horizontal center">
          <StyledToggleButtonGroup
            size="small"
            value={tab}
            exclusive
            onChange={handleTab}
            aria-label="text alignment"
            className="flex"
            color="primary"
            defaultValue={tab}>
            <ToggleButton value="ALL" aria-label="centered">
              <span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'all' })}</span>
            </ToggleButton>

            <ToggleButton value="NOTIF" aria-label="left aligned">
              <span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'notification' })}</span>
            </ToggleButton>

            <ToggleButton value="ARCHIVED" aria-label="left aligned">
              <span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'archived' })}</span>
            </ToggleButton>

            {/* <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} /> */}
          </StyledToggleButtonGroup>
          <div>
            {/* {
              showView &&
              (
                <IconButton
                  onClick={handleHide}
                  color="secondary"
                  aria-label="Cacher la zone"
                >
                  <KeyboardArrowUp />
                </IconButton>
              )
            } */}

            {/* {!showView && <IconButton onClick={(event) => setShowView(true)} color="secondary" aria-label="Afficher la zone">
              <KeyboardArrowUp />
            </IconButton>} */}
          </div>
        </Paper>
      </Box>
      {showView && tab === 'ALL' && (
        <div style={{ marginTop: '10px' }}>
          <QuestionList
            params={{
              ...paramsFilter,
              version: 'CURRENT',
              archive: '0',
              title: formatMessage({ id: 'list_of_questions' }),
              subtitle: formatMessage({ id: 'see_all_questions' })
            }}
          />
        </div>
      )}

      {showView && tab === 'NOTIF' && (
        <div style={{ marginTop: '10px' }}>
          <QuestionList
            params={{
              ...paramsFilter,
              version: 'NOTIF',
              archive: '0',
              title: formatMessage({ id: 'list_of_notifications' }),
              subtitle: formatMessage({ id: 'see_all_notifications' })
            }}
          />
        </div>
      )}
      {showView && tab === 'ARCHIVED' && (
        <div style={{ marginTop: '10px' }}>
          <QuestionList
            params={{
              ...paramsFilter,
              version: 'CURRENT',
              archive: '1',
              title: formatMessage({ id: 'list_of_archived_questions' }),
              subtitle: formatMessage({ id: 'see_all_archived_questions' })
            }}
          />
        </div>
      )}
    </>
  );
}

export default AdminPage;
