import {
  Box,
  FormControl,
  // IconButton,
  // InputBase,
  InputLabel,
  LinearProgress,
  MenuItem,
  // Paper,
  Select
} from '@mui/material';
import { useEffect, useState } from 'react';
import AppProgress from './AppProgress';
import StructureService from '../../modules/space/services/structure-service';
// import SearchIcon from '@mui/icons-material/Search';
import { useIntl } from 'react-intl';

const LINEAR_PROGRESS = 2;
const CIRCULAR_PROGRESS = 1;

function FilterData(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [structure, setStructure] = useState({});
  const [pays, setPays] = useState('');
  const [classe, setClasse] = useState('');
  const [subject, setSubject] = useState('');
  const [topic, setTopic] = useState('');
  const [isLoading, setIsLoading] = useState(0);

  const [paysLists, setPaysLists] = useState([]);
  const [classeLists, setClasseLists] = useState([]);
  const [subjectLists, setSubjectLists] = useState([]);
  const [topicLists, setTopicLists] = useState([]);
  const [search, setSearch] = useState('');
  const [path, setPath] = useState('');
  const [topicPath, setTopicPath] = useState('');

  useEffect(() => {
    // const dataPath = computePath();
    getPartOfStructure();
  }, []);

  const handlePaysChange = async (event) => {
    setPays(event.target.value);
    const allClasses = [];
    const params = {
      source: 'country',
      value: event.target.value.code
    };
    setIsLoading(2);
    setClasseLists([]);
    setClasse('');
    setSubjectLists([]);
    setSubject('');
    setTopicLists([]);
    setTopic('');
    await StructureService.getPartOfStructure(params)
      .then((response) => {
        for (const classe of response?.data?.structure ?? []) {
          allClasses.push(classe);
        }
        setIsLoading(0);
        setClasseLists(allClasses);
      })
      .catch((err) => {
        setIsLoading(0);
      });
  };
  const handleClasseChange = async (event) => {
    setClasse(event.target.value);
    const allSubjects = [];

    const params = {
      source: 'classes',
      value: pays.code + '#' + event.target.value.code
    };
    setIsLoading(2);
    setSubjectLists([]);
    setSubject('');
    setTopic('');
    setTopicLists([]);
    await StructureService.getPartOfStructure(params)
      .then((response) => {
        for (const subject of response?.data?.structure ?? []) {
          allSubjects.push(subject);
        }
        setIsLoading(0);
        setSubjectLists(allSubjects);
      })
      .catch((err) => {
        setIsLoading(0);
      });
  };
  const handleSubjectChange = async (event) => {
    setSubject(event.target.value);
    const allTopics = [];
    const params = {
      source: 'subject',
      value: pays.code + '#' + classe.code + '#' + event.target.value.code
    };
    setIsLoading(2);
    setTopicLists([]);
    setTopic('');
    await StructureService.getPartOfStructure(params)
      .then((response) => {
        for (const topic of response?.data?.structure ?? []) {
          allTopics.push(topic);
        }
        setIsLoading(0);
        setTopicLists(allTopics);
      })
      .catch((err) => {
        setIsLoading(0);
      });
  };
  const handleTopicChange = async (event) => {
    setTopic(event.target.value);
    const path = `${pays.code}.${classe.code}.${subject.code}`;
    const topicPath = `${pays.code}#${classe.code}#${subject.code}#${event.target.value.code}`;
    setTopicPath(topicPath);
    setPath(path);
    if (props.onSetParams) {
      const params = {
        path,
        topicPath,
        search
      };
      props.onSetParams(params);
    }
  };
  const onKeyUp = (e) => {
    const enterKey = 13; // Key Code for Enter Key
    if (e.which == enterKey) {
      if (props.onSetParams) {
        const params = {
          path,
          topicPath,
          search
        };
        props.onSetParams(params);
      }
    }
  };
  const doSearch = (event) => {
    if (props.onSetParams) {
      const params = {
        path,
        topicPath,
        search
      };
      props.onSetParams(params);
    }
  };
  async function getPartOfStructure() {
    setIsLoading(1);
    await StructureService.getPartOfStructure({ source: '', value: '' })
      .then((response) => {
        console.log('getPartOfStructure/response =', response);
        const allPays = [];
        for (const pays of response?.data?.structure ?? []) {
          allPays.push(pays);
        }
        setPaysLists(allPays);
        setIsLoading(0);
      })
      .catch((err) => {
        setIsLoading(0);
      });
  }

  return (
    <>
      {isLoading === LINEAR_PROGRESS && (
        <Box sx={{ pt: '10px', pb: '10px', width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {isLoading === CIRCULAR_PROGRESS && <AppProgress />}
      {isLoading !== CIRCULAR_PROGRESS && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))',
            alignItems: 'center',
            minWidth: '100%'
          }}>
          <FormControl size="small" sx={{ m: 1 }}>
            <InputLabel>{formatMessage({ id: 'country' })}</InputLabel>
            <Select
              labelId="pays-select"
              value={pays}
              label={formatMessage({ id: 'country' })}
              onChange={handlePaysChange}>
              {paysLists.map((item) => (
                <MenuItem value={item} key={item.code}>
                  {item.name_fr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ m: 1 }}>
            <InputLabel>{formatMessage({ id: 'class' })}</InputLabel>
            <Select
              labelId="classe-select"
              value={classe}
              label={formatMessage({ id: 'class' })}
              onChange={handleClasseChange}>
              {classeLists.map((item) => (
                <MenuItem value={item} key={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ m: 1 }}>
            <InputLabel>{formatMessage({ id: 'subject' })}</InputLabel>
            <Select
              labelId="matiere-select"
              value={subject}
              label={formatMessage({ id: 'subject' })}
              onChange={handleSubjectChange}>
              {subjectLists.map((item) => (
                <MenuItem value={item} key={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ m: 1 }}>
            <InputLabel>{formatMessage({ id: 'topic' })}</InputLabel>
            <Select
              labelId="topic-select"
              value={topic}
              label={formatMessage({ id: 'topic' })}
              onChange={handleTopicChange}>
              {topicLists.map((item) => (
                <MenuItem value={item} key={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Paper
            sx={{
              m: 1,
              display: 'flex',
              alignItems: 'center',
              height: 38,
              verticalAlign: 'middle'
            }}>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                verticalAlign: 'middle'
              }}
              onKeyUp={onKeyUp}
              type="text"
              onChange={(event) => setSearch(event.target.value)}
              value={search}
              placeholder={formatMessage({ id: 'search_question' })}
              inputProps={{ 'aria-label': 'Rechercher une question' }}
            />
            <IconButton
              onClick={doSearch}
              type="button"
              sx={{ p: '10px' }}
              aria-label={formatMessage({ id: 'search' })}>
              <SearchIcon />
            </IconButton>
            </Paper> */}
        </div>
      )}
    </>
  );
}

export default FilterData;
