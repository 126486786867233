import AdminPage from './pages/AdminPage';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import Footer from '../../components/shared/Footer';

function AdminModule() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const [metadata, setMetadata] = useState({});

  return (
    <div className="layout">
      <div className="flex" style={{ marginTop: '10px', marginRight: '10px', paddingLeft: '10px' }}>
        <div style={{ paddingTop: '15px' }} className="flex">
          <AdminPage metadata={metadata} />
        </div>
      </div>
      <Box
        sx={{
          bottom: 0,
          mt: 2
        }}>
        <Footer />
      </Box>
    </div>
  );
}

export default AdminModule;
