import axios from 'axios';
import cookieService from '../../../cookieService';
import KEYS from '../../../utils/key';

const authHeader = () => {
  const token = cookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      'x-access-token': token,
      'Content-Type': 'application/json'
    };
  } else {
    return {};
  }
};

const getStructure = (params, path) => {
  const url = KEYS.backendPath + '/quiz/structure/get-raw';
  const headers = authHeader();
  return axios.get(url, { params: params, headers: headers });
};

const getPartOfStructure = (params) => {
  const url = KEYS.backendPath + '/quiz/structure/level';
  const headers = authHeader();
  return axios.get(url, { params: params, headers: headers });
};

const getTopicPathLabel = (params, path) => {
  const url = KEYS.backendPath + '/quiz/structure/topic-path-label';
  const headers = authHeader();
  return axios.get(url, { params: params, headers: headers });
};
const StructureService = {
  getStructure,
  getTopicPathLabel,
  getPartOfStructure
};
export default StructureService;
