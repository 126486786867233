import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setSelectedCountry } from '../../stores/slices/userSlice';
import { useEffect } from 'react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

export default function ListContriesBar() {
  const navigate = useNavigate();

  const intl = useIntl();

  const { formatMessage } = intl;

  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const country = user.countries[event.currentTarget.dataset.myValue];
    console.log('country', country);
    setAnchorEl(null);
    if (country != null) {
      dispatch(setSelectedCountry(country));
      navigate('/space/' + country.code.toLowerCase(), { replace: true });
    }
  };

  const userListCountries = Object.values(user?.countries);

  const selectedCountry = user.selectedCountry;

  useEffect(() => {
    if (!user.selectedCountry || user.selectedCountry === null) {
      const country = user.countries[Object.keys(user.countries)[0]];
      if (country != null) dispatch(setSelectedCountry(country));
    }
  }, [dispatch, user.countries, user.selectedCountry]);

  return (
    <Paper
      elevation={0}
      display="flex"
      sx={{
        display: 'flex',
        padding: '10px',
        bgcolor: 'background.paper'
      }}
      className="layout horizontal center end-justified">
      <div className="flex layout horizontal end-justified">
        <div className="layout vertical" style={{ paddingRight: '10px' }}>
          <Typography
            variant="b"
            component="span"
            sx={{ fontWeight: 'bold', textAlign: 'right' }}
            gutterBottom>
            {formatMessage({ id: 'select_country' })}
          </Typography>
          <Typography
            variant="small"
            color="primary"
            component="small"
            sx={{ textAlign: 'right', fontWeight: 500 }}
            gutterBottom>
            {formatMessage({ id: 'select_country_where_you_want_to_work' })}
          </Typography>
        </div>
      </div>

      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          sx={{ fontWeight: 'bold', fontSize: '1 rem' }}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}>
          {selectedCountry != null ? selectedCountry.name : formatMessage({ id: 'select_country' })}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {userListCountries.map((country, index) => (
            <MenuItem onClick={handleClose} disableRipple key={index} data-my-value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    </Paper>
  );
}
