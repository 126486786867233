import {
  Check,
  CheckBox,
  CheckBoxOutlined,
  CheckCircle,
  Clear,
  Delete,
  FilterListOutlined,
  RadioButtonUnchecked
} from '@mui/icons-material';
import { CardMedia, Chip, Link, Paper, Typography } from '@mui/material';
import AppTitle from '../AppTitle';

import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import KEYS from '../../../utils/key';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Launch } from '@mui/icons-material';
import { cancelEventPropagation, QUESTION_TYPE_COLOR } from '../../../utils/Utils';
import TopicPathToText from '../TopicPathToText';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import EnumQuestionType from '../../../utils/EnumQuestionType';

function QuestionViewer(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { question } = props;
  console.log('THE QUESTION TO VIEW', question);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCopyRef = (event) => {
    cancelEventPropagation(event);
    // copyClipboard(props.question.questionId);
    copy(question.questionId);

    enqueueSnackbar('La référence a été copiée dans votre presse-papier.', {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });
  };

  return (
    <>
      <Paper elevation={0} sx={{ p: '10px', mt: 1 }}>
        <div className="layout horizontal center" style={{ paddingBottom: '15px' }}>
          <div className="flex">
            <AppTitle
              icon={<FilterListOutlined />}
              title={formatMessage({ id: 'question_details' })}
              subtitle={formatMessage({ id: 'question_selected_details' })}
            />
          </div>
          <div>
            <Button
              title={question.topicPath}
              onClick={onCopyRef}
              sx={{ textTransform: 'none' }}
              variant="outlined"
              size="small">
              {formatMessage({ id: 'copy_reference' })}
            </Button>
          </div>
        </div>
        <div>
          <div style={{ padding: '10px 0', backgroundColor: 'white' }}>
            <TopicPathToText topicPath={question.topicPath} />
          </div>
          <div style={{ padding: '10px', backgroundColor: '#EAEAEA' }}>
            <span>{question.content}</span>
          </div>
          <div className="layout horizontal" style={{ margin: '10px 0' }}>
            <span style={{ padding: '5px', backgroundColor: '#EAEAEA', fontSize: '12px' }}>
              {question && question.type === EnumQuestionType.OPEN && (
                <Typography color={QUESTION_TYPE_COLOR.OPEN} component="b" variant="b">
                  {formatMessage({ id: 'open_answer_question' })}
                </Typography>
              )}
              {question && question.type === EnumQuestionType.MULTI_CHOICE && (
                <Typography color={QUESTION_TYPE_COLOR.MULTI_CHOICE} component="b" variant="b">
                  {formatMessage({ id: 'MCQ' })}
                </Typography>
              )}
              {question && question.type === EnumQuestionType.SINGLE_CHOICE && (
                <Typography color={QUESTION_TYPE_COLOR.SINGLE_CHOICE} component="b" variant="b">
                  {formatMessage({ id: 'single_choice' })}
                </Typography>
              )}
            </span>
            <span
              style={{
                marginLeft: '10px',
                padding: '5px',
                backgroundColor: '#EAEAEA',
                fontSize: '12px'
              }}>
              <b>{question.mark}</b> {formatMessage({ id: 'marks' })}
            </span>
            <span
              style={{
                marginLeft: '10px',
                padding: '5px',
                backgroundColor: '#EAEAEA',
                fontSize: '12px'
              }}>
              <b>{question.duration}</b> {formatMessage({ id: 'minuts' })}
            </span>
            <span
              style={{
                marginLeft: '10px',
                padding: '5px',
                backgroundColor: '#EAEAEA',
                fontSize: '12px'
              }}>
              <b>{question.images?.length ?? 0}</b> {formatMessage({ id: 'image' })}
            </span>
            {question.children &&
              Array.isArray(question.children) &&
              question.children.length > 0 && (
                <span
                  style={{
                    marginLeft: '10px',
                    padding: '5px',
                    backgroundColor: '#EAEAEA',
                    fontSize: '12px'
                  }}>
                  <b>{question.children.length}</b> {formatMessage({ id: 'copy' })}
                </span>
              )}
            {question.parent && (
              <Link
                underline="none"
                component="button"
                variant="span"
                onClick={(event) => {
                  cancelEventPropagation(event);
                  if (props.onShowParentDetail) props.onShowParentDetail(question.parent);
                }}>
                <span
                  style={{ marginLeft: '10px', color: 'blue' }}
                  className="layout horizontal center">
                  <span
                    style={{
                      fontSize: '13px',
                      lineHeight: '0.8'
                    }}>
                    {formatMessage({ id: 'see_the_source' })}
                  </span>
                  <Launch sx={{ fontSize: '1rem !important' }} fontSize="inherit" />
                </span>
              </Link>
            )}
          </div>
          {(question.images?.length ?? 0) > 0 && (
            <div style={{ margin: '10px 0' }} className="layout horizontal center wrap">
              {question.images.map((image, index) => (
                <div key={index} style={{ marginRight: '10px', width: '75px', height: '75px' }}>
                  <CardMedia
                    component="img"
                    height="75px"
                    width="75px"
                    image={KEYS.RESSOURCE_BASE_PATH + '/' + image}
                    alt="Paella dish"
                    style={{ objectFit: 'contain' }}
                  />
                </div>
              ))}
            </div>
          )}
          {question && question.type === EnumQuestionType.OPEN && (
            <div style={{ margin: '10px 0' }}>
              <div style={{ padding: '10px', backgroundColor: '#EAEAEA' }}>
                <span style={{ color: 'green' }}>{question.answer}</span>
              </div>
              <div style={{ padding: '10px 0' }}>
                {(question.answerTags?.length ?? 0) > 0 && (
                  <>
                    {question.answerTags.map((tag, index) => (
                      <span style={{ padding: '0 10px 0 0' }} key={index}>
                        <Chip label={tag} size="small" />
                      </span>
                    ))}
                  </>
                )}
                {(question.answerTags?.length ?? 0) === 0 && (
                  <span>{formatMessage({ id: 'no_tags' })}</span>
                )}
              </div>
              {question.explanation && (
                <div style={{ padding: '10px', backgroundColor: '#EAEAEA' }}>
                  <span style={{ color: 'black' }}>{question.explanation}</span>
                </div>
              )}
            </div>
          )}

          {question && question.type === EnumQuestionType.MULTI_CHOICE && (
            <div style={{ margin: '10px 0' }}>
              {(question.options?.length ?? 0) > 0 && (
                <>
                  {question.options.map((option, index) => (
                    <div
                      className="layout horizontal center"
                      style={{ padding: '0 10px 5px 0' }}
                      key={index}>
                      {option.isCorrect ? (
                        <CheckBox title="option juste" color="success" />
                      ) : (
                        <CheckBoxOutlined color="default" />
                      )}
                      <span
                        style={{
                          marginLeft: '10px',
                          padding: '5px',
                          backgroundColor: '#EAEAEA',
                          fontSize: '12px'
                        }}>
                        {option.content}
                      </span>
                      {option.explanation && (
                        <div
                          style={{
                            marginLeft: '10px',
                            padding: '5px',
                            backgroundColor: '#EAEAEA',
                            fontSize: '12px'
                          }}>
                          <span style={{ color: 'black' }}>{option.explanation}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
              {(question.options?.length ?? 0) === 0 && (
                <span>{formatMessage({ id: 'no_option' })}</span>
              )}
            </div>
          )}
          {question && question.type === EnumQuestionType.SINGLE_CHOICE && (
            <div style={{ margin: '10px 0' }}>
              {(question.options?.length ?? 0) > 0 && (
                <>
                  {question.options.map((option, index) => (
                    <div
                      className="layout horizontal center"
                      style={{ padding: '0 10px 5px 0' }}
                      key={index}>
                      {question.answer == index ? (
                        <CheckCircle title="option juste" color="success" />
                      ) : (
                        <RadioButtonUnchecked color="default" />
                      )}
                      <span
                        style={{
                          marginLeft: '10px',
                          padding: '5px',
                          backgroundColor: '#EAEAEA',
                          fontSize: '12px'
                        }}>
                        {option.content}
                      </span>

                      {option.explanation && (
                        <div
                          style={{
                            marginLeft: '10px',
                            padding: '5px',
                            backgroundColor: '#EAEAEA',
                            fontSize: '12px'
                          }}>
                          <span style={{ color: 'black' }}>{option.explanation}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
              {(question.options?.length ?? 0) === 0 && (
                <span>{formatMessage({ id: 'no_option' })}</span>
              )}
            </div>
          )}
          <div className="layout vertical" style={{ padding: '10px 0' }}>
            <Alert
              sx={{ p: '0px 5px' }}
              icon={question.enabled ? <Check size="small" /> : <Clear size="small" />}
              severity={question.enabled ? 'success' : 'warning'}>
              <AlertTitle sx={{ mb: 0 }}>
                {question.enabled
                  ? formatMessage({ id: 'activated' })
                  : formatMessage({ id: 'unactivated' })}
              </AlertTitle>
              <div className="layout vertical">
                <span style={{ fontSize: '12px' }}>
                  {formatMessage(
                    { id: 'add_date_user' },
                    {
                      date: <PostDate date={question.createdAt} />,
                      user: (
                        <a
                          style={{}}
                          className="no-underline capitalize"
                          title={question.createdBy}
                          rel="noopener noreferrer"
                          target="_blank"
                          href={'#'}>
                          {question.createdBy}
                        </a>
                      )
                    }
                  )}
                </span>
                {question.updatedAt && (
                  <span style={{ fontSize: '10px' }}>
                    {formatMessage(
                      { id: 'update_date_user' },
                      {
                        date: <PostDate date={question.updatedAt} />,
                        user: (
                          <a
                            style={{}}
                            className="no-underline capitalize"
                            title={question.updatedBy}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={'#'}>
                            {question.updatedBy}
                          </a>
                        )
                      }
                    )}
                  </span>
                )}
              </div>
            </Alert>
            {question.isDeleted && (
              <Alert
                sx={{ p: '0px 5px', mt: '10px' }}
                icon={<Delete size="small" />}
                severity="error">
                <AlertTitle sx={{ mb: 0 }}>{formatMessage({ id: 'deleted' })}</AlertTitle>
                <span style={{ fontSize: '12px' }}>
                  {formatMessage(
                    { id: 'delete_date_user' },
                    {
                      date: <PostDate date={question.deletedAt} />,
                      user: (
                        <a
                          style={{}}
                          className="no-underline capitalize"
                          title={question.deletedBy}
                          rel="noopener noreferrer"
                          target="_blank"
                          href={'#'}>
                          {question.deletedBy}
                        </a>
                      )
                    }
                  )}
                </span>
              </Alert>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
}
export default QuestionViewer;

const PostDate = (props) => {
  const intl = useIntl();
  return (
    <span title={intl.formatDate(props.date)}>
      {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
      <FormattedDate day="numeric" month="long" year="numeric" value={props.date} />
      &nbsp;{intl.formatMessage({ id: 'code_a' })} &nbsp;
      <FormattedTime hour="numeric" minute="numeric" value={props.date} />
    </span>
  );
};
