import { Add, RemoveCircle } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import { useDispatch, useSelector } from 'react-redux';
import { selectMulti, setMulti } from '../../../../../stores/slices/newQuestionSlice';

import { useIntl } from 'react-intl';

function QuestionAddMulti(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const multi = useSelector(selectMulti);
  const dispatch = useDispatch();

  const initialDatas = JSON.parse(JSON.stringify(multi?.options ?? [])) ?? [];
  while (initialDatas.length < 2) {
    initialDatas.push({
      isCorrect: false,
      content: '',
      explanation: ''
    });
  }

  const [options, setOptions] = useState(initialDatas);
  useEffect(() => {
    function handleClearAddFormEvent(event) {
      setOptions([
        {
          isCorrect: false,
          content: '',
          explanation: ''
        },
        {
          isCorrect: false,
          content: '',
          explanation: ''
        }
      ]);
    }

    document.addEventListener('clear-add-form', handleClearAddFormEvent);
    return () => {
      document.removeEventListener('clear-add-form', handleClearAddFormEvent);
    };
  }, []);
  useEffect(() => {
    dispatch(setMulti({ options: JSON.parse(JSON.stringify(options)) }));
  }, [options]);

  useEffect(() => {
    console.log('Props multi changed');
  }, [props]);
  const doAddOption = (event) => {
    setOptions([
      ...options,
      {
        isCorrect: false,
        content: '',
        explanation: ''
      }
    ]);
  };
  const updateCheck = (event, index) => {
    const data = [...options];
    data[index]['isCorrect'] = event.target.checked;
    setOptions(data);
  };
  const updateText = (event, index) => {
    const data = [...options];
    data[index]['content'] = event.target.value;
    setOptions(data);
  };
  const updateExplanation = (event, index) => {
    const data = [...options];
    data[index]['explanation'] = event.target.value;
    setOptions(data);
  };
  const removeFields = (index) => {
    const data = [...options];
    data.splice(index, 1);
    setOptions(data);
  };
  const corrects = options.reduce(function (str, val, index) {
    const comma = str.length ? ',  ' : '';
    return val.isCorrect ? str + comma + (index + 1) : str;
  }, '');
  function getError(errors, code) {
    const error = errors.find((err) => err.code === code);
    return error;
  }
  return (
    <>
      <div style={{ marginTop: '10px' }} className="layout vertical">
        {props.errorMessages.length > 0 && (
          <div className="error" style={{ color: 'red' }}>
            {getError(props.errorMessages, 'options') &&
              getError(props.errorMessages, 'options').message}
            {getError(props.errorMessages, 'MULTI_OPTIONS_LENGTH_ERROR') &&
              getError(props.errorMessages, 'MULTI_OPTIONS_LENGTH_ERROR').message}
            {getError(props.errorMessages, 'explanation') &&
              getError(props.errorMessages, 'explanation').message}
          </div>
        )}
        <div className="layout horizontal">
          <FormControl
            className="flex-9"
            disabled={props.submitting}
            sx={{}}
            component="fieldset"
            variant="standard">
            <FormLabel component="legend">
              {formatMessage({ id: 'answer_option' })}(
              <small>{formatMessage({ id: 'check_correct_answer_options' })}</small>)
            </FormLabel>
            <FormGroup>
              {options.map((option, index) => (
                <div key={index} className="layout horizontal center">
                  <FormControlLabel
                    className="flex"
                    sx={{
                      mt: 1.5,
                      '.MuiFormControlLabel-label': {
                        flex: '1 1',
                        flexBasis: '1e-9px'
                      },
                      '.MuiFormControlLabel-label > div': {
                        width: '100%'
                      }
                    }}
                    control={
                      <Checkbox
                        checked={option.isCorrect}
                        onChange={(event) => updateCheck(event, index)}
                        name={'option' + (index + 1)}
                      />
                    }
                    label={
                      <Stack spacing={2} direction="row" sx={{ width: '100%' }}>
                        <TextField
                          disabled={props.submitting}
                          size="small"
                          value={option.content}
                          onChange={(event) => updateText(event, index)}
                          label={formatMessage({ id: 'response_option' }) + (index + 1)}
                          placeholder={
                            formatMessage({ id: 'enter_the_response_option' }) + (index + 1)
                          }
                          sx={{ width: '100%' }}
                        />
                        <TextField
                          disabled={props.submitting}
                          size="small"
                          value={option.explanation}
                          onChange={(event) => updateExplanation(event, index)}
                          label={formatMessage({ id: 'explanation_answer' }) + (index + 1)}
                          placeholder={
                            formatMessage({ id: 'enter_explanation_for_the_option' }) + (index + 1)
                          }
                          sx={{ width: '100%' }}
                        />
                      </Stack>
                    }
                  />
                  {options.length > 2 && (
                    <IconButton
                      disabled={props.submitting}
                      onClick={() => removeFields(index)}
                      color="secondary"
                      title={formatMessage({ id: 'remove_this_question' })}
                      aria-label={formatMessage({ id: 'remove_this_question' })}>
                      <RemoveCircle />
                    </IconButton>
                  )}
                </div>
              ))}
            </FormGroup>
            <FormHelperText>
              {corrects.length > 0
                ? formatMessage({ id: 'true_option' }) + ': ' + corrects
                : formatMessage({ id: 'none' })}
            </FormHelperText>
          </FormControl>
          <div className="flex-3"></div>
        </div>
        <div className="layout horizontal center">
          <Typography variant="button" title={formatMessage({ id: 'add_an_option_at_the_end' })}>
            {formatMessage({ id: 'add_an_option' })}
          </Typography>
          <IconButton
            disabled={props.submitting}
            onClick={doAddOption}
            color="secondary"
            aria-label={formatMessage({ id: 'add_an_option' })}>
            <Add />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default QuestionAddMulti;
