import { createSlice } from '@reduxjs/toolkit';
import KEYS from '../../utils/key';

function getUserStorage() {
  try {
    return JSON.parse(localStorage.getItem(KEYS.user));
  } catch (e) {
    return null;
  }
}

const initialState = getUserStorage();

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(KEYS.user, JSON.stringify(action.payload));
      return action.payload;
    },

    clearUser: (state) => {
      localStorage.removeItem(KEYS.user);
      return null;
    },

    setSelectedCountry: (state, action) => {
      // state.selectedCountry = action.payload;
      state = { ...state, selectedCountry: action.payload };
      localStorage.setItem(KEYS.CURRENT_COUNTRY, JSON.stringify(state));
      return state;
    }
  }
});

export const { setUser, clearUser, setSelectedCountry } = userSlice.actions;

export const selectUser = (state) => state.user;

export const selectedCountry = (state) => (state.user != null ? state.user.selectedCountry : null);

export const userReducer = userSlice.reducer;
