import { Check, Clear, Delete, Edit, AddBoxRounded } from '@mui/icons-material';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import QuestionItemGeneric from '../../../../../components/shared/question/QuestionItemGeneric';
import { cancelEventPropagation, convertTopicPathToXressource } from '../../../../../utils/Utils';
import QuestionAddSub from './../subquestion/QuestionAddSub';
import { Box } from '@mui/material';
import { dataToItem, itemToData } from 'dynamo-converters';
import QuestionEdit from '../edit/QuestionEdit';
import { useDispatch } from 'react-redux';
import {
  setOpen,
  setGeneric,
  setSingle,
  resetData,
  setMulti
} from '../../../../../stores/slices/editQuestionSlice';
import EnumQuestionType from '../../../../../utils/EnumQuestionType';
import QuestionService from '../../../services/question-service';
import { useSnackbar } from 'notistack';

function QuestionItem(props) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispath = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onConfirmArchive = (event) => {
    setOpenDelete(false);
    if (questionIndex) {
      onConfirmArchiveSubQuestion();
    } else {
      if (props.onArchive) props.onArchive();
    }
  };

  const onClickArchive = (event, question) => {
    setSelectQuestion(question);
    cancelEventPropagation(event);
    setOpenDelete(true);
  };
  const onClickEnabled = (event, question) => {
    setSelectQuestion(question);
    cancelEventPropagation(event);
    if (props.onChangeStatus) props.onChangeStatus(1);
  };
  const onClickDisabled = (event, question) => {
    setSelectQuestion(question);
    cancelEventPropagation(event);
    if (props.onChangeStatus) props.onChangeStatus(0);
  };
  const onClickEdit = (event, question) => {
    setSelectQuestion(question);
    cancelEventPropagation(event);
    if (props.onEdit) props.onEdit();
  };

  const openAddSubQuestion = (event, parent) => {
    setSelectQuestion(parent);
    cancelEventPropagation(event);
    setIsShowAdd(true);
  };
  const onClickEditSubQuestion = (event, index, question) => {
    setSelectQuestion(question);
    setQuestionIndex(index);
    cancelEventPropagation(event);

    dispath(resetData());
    const genericData = (({ answer, options, answerTags, ...o }) => o)(question);
    console.log('Generic data', genericData);
    dispath(setGeneric(genericData));
    switch (question.type) {
      case EnumQuestionType.OPEN:
        dispath(
          setOpen({
            answer: question.answer,
            answerTags: question.answerTags,
            explanation: question.explanation
          })
        );
        break;
      case EnumQuestionType.MULTI_CHOICE:
        dispath(setMulti({ options: JSON.parse(JSON.stringify(question.options)) }));
        break;
      case EnumQuestionType.SINGLE_CHOICE:
        dispath(
          setSingle({
            options: JSON.parse(JSON.stringify(question.options)),
            answer: question.answer
          })
        );
        break;
    }
    console.log('doEdit/genericData = ', genericData);
    setIsShowEdit(true);
  };
  const onClickChangeStatusSubQuestion = (event, index, question, newStatus) => {
    setSelectQuestion(question);
    setQuestionIndex(index);
    cancelEventPropagation(event);
    const query = {
      version: question.version,
      questionId: question.questionId,
      status: newStatus
    };
    const path = convertTopicPathToXressource(question.topicPath);
    // setIsLoading(true);
    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });

    QuestionService.setStatusQuestion(query, path)
      .then((response) => {
        closeSnackbar(queueSnack);
        enqueueSnackbar(
          newStatus === 1
            ? formatMessage({ id: 'question_activated_successfully' })
            : formatMessage({ id: 'question_unactivated_successfully' }),
          {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          }
        );

        const newQuestions = subQuestions.map((item) => {
          const data = itemToData(item);
          if (data.questionId === question.questionId)
            data.enabled = parseInt(newStatus) === 0 ? false : true;
          return dataToItem(data);
        });
        setSubQuestions(newQuestions);
        setQuestionIndex(null);
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);
        setQuestionIndex(null);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (err?.response?.data?.code) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar(formatMessage({ id: 'most_fill_question_and_status' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_VALUE':
            enqueueSnackbar(formatMessage({ id: 'status_values' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;

          default:
            enqueueSnackbar(formatMessage({ id: 'error_occur' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
        // setHasError(true);
        // setIsLoading(false);
      });
  };
  const onClickArchiveSubQuestion = (event, index, question) => {
    setSelectQuestion(question);
    setQuestionIndex(index);
    cancelEventPropagation(event);
    setOpenDelete(true);
  };
  const onConfirmArchiveSubQuestion = () => {
    setOpenDelete(false);
    console.log('doArchive question', questionIndex, selectQuestion);
    const path = convertTopicPathToXressource(selectQuestion.topicPath);
    console.log('Path', path);
    // setIsLoading(true);
    const queueSnack = enqueueSnackbar(formatMessage({ id: 'in_progress' }), {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });
    QuestionService.archiveQuestion(selectQuestion.questionId, selectQuestion.version, path)
      .then((response) => {
        console.log(response);

        const newQuestions = subQuestions.filter(
          (item) => itemToData(item).questionId !== selectQuestion.questionId
        );
        setSubQuestions(newQuestions);
        enqueueSnackbar(formatMessage({ id: 'archive_success' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        closeSnackbar(queueSnack);
        console.log(err);

        // TODO: traiter les eventuels messages d'erreurs ici et les afficher dans le snackbar.
        switch (
          err?.response?.data?.code // err?.response?.data?.code
        ) {
          case 'UNAUTHORIZED':
            enqueueSnackbar(formatMessage({ id: 'unautorize_operation' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'ERR_BAD_REQUEST':
            enqueueSnackbar('Oupps!! vous devez renseigner la question', {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(formatMessage({ id: 'question_not_found' }), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: 'error_occur' }), {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          // ...TODO: ajouter les autres cas ici. les trouver dans le controller au backend
        }
      });
    setQuestionIndex(null);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [isShowAdd, setIsShowAdd] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [subQuestions, setSubQuestions] = useState([]);
  const [selectQuestion, setSelectQuestion] = useState(props.question);
  const [questionIndex, setQuestionIndex] = useState(null);

  const handleCloseAdd = () => {
    setIsShowAdd(false);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  // Récupérer la liste des sous question
  const getSubQuestions = (data) => {
    props.question.subQuestions = data;
    setSubQuestions(data);
    console.log(props.question.subQuestions);
  };

  // Le resultat renvoyé après l'ajout d'une question
  const getSubQuestionResult = (data) => {
    setIsShowAdd(false);
    console.log(data);
    setSubQuestions([...subQuestions, dataToItem(data)]);
    console.log(data);
  };

  const handleCloseEdit = (newQuestion) => {
    console.log('Handle closes Edit', newQuestion);
    setIsShowEdit(false);
    if (newQuestion && newQuestion !== null && questionIndex != null) {
      const datas = [...subQuestions];
      datas[parseInt(questionIndex)] = dataToItem(newQuestion);
      setSubQuestions(datas);
    }
    setQuestionIndex(null);
    setTimeout(() => dispath(resetData()), 500);
  };

  const { index, question } = props;
  return (
    <>
      <QuestionItemGeneric
        metadata={props.metadata}
        question={props.question}
        index={props.index}
        onSetSubQuestions={(data) => getSubQuestions(data)}
        actions={
          <div className="layout horizontal center">
            <IconButton
              onClick={(e) => openAddSubQuestion(e, props.question)}
              size="small"
              color="primary"
              title={formatMessage({ id: 'add_subquestion' })}
              edge="end"
              aria-label="add">
              <AddBoxRounded size="small" />
            </IconButton>
            <IconButton
              onClick={(e) => onClickEdit(e, props.question)}
              size="small"
              color="primary"
              title={formatMessage({ id: 'edit_question' })}
              edge="end"
              aria-label="edit">
              <Edit size="small" />
            </IconButton>
            {question.enabled && (
              <IconButton
                onClick={(e) => onClickDisabled(e, props.question)}
                size="small"
                color="success"
                title={formatMessage({
                  id: 'question_enabled_click_to_desable'
                })}
                edge="end"
                aria-label="check">
                <Check size="small" />
              </IconButton>
            )}
            {!question.enabled && (
              <IconButton
                onClick={(e) => onClickEnabled(e, props.question)}
                size="small"
                color="error"
                title={formatMessage({ id: 'question_desabled_click_to_enable' })}
                edge="end"
                aria-label="clear">
                <Clear size="small" />
              </IconButton>
            )}
            <IconButton
              onClick={(e) => onClickArchive(e, props.question)}
              size="small"
              color="error"
              title={formatMessage({ id: 'delete_question' })}
              edge="end"
              aria-label="delete">
              <Delete size="small" />
            </IconButton>
          </div>
        }
      />
      {subQuestions.length > 0 && (
        <Box
          sx={{
            paddingLeft: '10px',
            marginBottom: '5px',
            marginLeft: '47px',
            borderLeft: '1px solid #0000000f',
            backgroundColor: '#0000001f'
          }}>
          {subQuestions.length > 0 && (
            <div style={{ color: '#656565', marginTop: '10px' }}>
              {formatMessage({ id: 'List_subquestions' })}
            </div>
          )}
          {subQuestions.map((question, i) => (
            <div key={i} style={{ paddingBottom: '10px' }}>
              <QuestionItemGeneric
                metadata={props.metadata}
                question={itemToData(question)}
                // question={question}
                index={i}
                onSetSubQuestions={(data) => getSubQuestions(data)}
                actions={
                  <div className="layout horizontal center">
                    <IconButton
                      onClick={(e) => openAddSubQuestion(e, itemToData(question))}
                      size="small"
                      color="primary"
                      title={formatMessage({ id: 'add_subquestion' })}
                      edge="end"
                      aria-label="add">
                      <AddBoxRounded size="small" />
                    </IconButton>
                    <IconButton
                      onClick={(e) => onClickEditSubQuestion(e, i, itemToData(question))}
                      size="small"
                      color="primary"
                      title={formatMessage({ id: 'edit_question' })}
                      edge="end"
                      aria-label="edit">
                      <Edit size="small" />
                    </IconButton>
                    {itemToData(question).enabled && (
                      <IconButton
                        onClick={(e) =>
                          onClickChangeStatusSubQuestion(e, i, itemToData(question), 0)
                        }
                        size="small"
                        color="success"
                        title={formatMessage({
                          id: 'question_enabled_click_to_desable'
                        })}
                        edge="end"
                        aria-label="check">
                        <Check size="small" />
                      </IconButton>
                    )}
                    {!itemToData(question).enabled && (
                      <IconButton
                        onClick={(e) =>
                          onClickChangeStatusSubQuestion(e, i, itemToData(question), 1)
                        }
                        size="small"
                        color="error"
                        title={formatMessage({ id: 'question_desabled_click_to_enable' })}
                        edge="end"
                        aria-label="clear">
                        <Clear size="small" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={(e) => onClickArchiveSubQuestion(e, i, itemToData(question))}
                      size="small"
                      color="error"
                      title={formatMessage({ id: 'delete_question' })}
                      edge="end"
                      aria-label="delete">
                      <Delete size="small" />
                    </IconButton>
                  </div>
                }
              />
            </div>
          ))}
        </Box>
      )}

      {/* Modal Confirmation suppression */}
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Confirmer la suppression'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formatMessage({ id: 'confirm_delete' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {' '}
            {formatMessage({ id: 'no' })}, {formatMessage({ id: 'cancel' })}
          </Button>
          <Button onClick={onConfirmArchive} autoFocus>
            {formatMessage({ id: 'yes' })}, {formatMessage({ id: 'delete' })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal Ajout d'une sous question */}
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={isShowAdd}
        onClose={(event) => handleCloseAdd()}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              // maxWidth: '750px' // Set your width here
            }
          }
        }}>
        <DialogContent>
          <QuestionAddSub
            parent={selectQuestion}
            closeSubQuestion={(e) => handleCloseAdd()}
            sendSubQuestion={(data) => getSubQuestionResult(data)}
            metadata={props.metadata}
          />
        </DialogContent>
      </Dialog>

      {/* Modal Edition d'une sous question */}
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={isShowEdit}
        onClose={(event) => handleCloseEdit(null)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              // maxWidth: '750px' // Set your width here
            }
          }
        }}>
        <DialogContent>
          <QuestionEdit onCloseEdit={(newQuestion) => handleCloseEdit(newQuestion)} />
        </DialogContent>
      </Dialog>
    </>
  );
}
export default QuestionItem;
