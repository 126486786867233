import {
  ArrowForwardIosOutlined,
  FilterListOffRounded,
  ImportExport,
  Info,
  RemoveCircle,
  ScienceRounded
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Paper from '@mui/material/Paper';
import { itemToData } from 'dynamo-converters';
import { useEffect, useRef, useState } from 'react';
import AppProgress from '../../../../../components/shared/AppProgress';
import EnumVersion from './../../../../../utils/EnumVersion';

import AppTitle from '../../../../../components/shared/AppTitle';
import QuestionService from '../../../services/question-service';
import FilterData from '../../../../../components/shared/FilterData';
import {
  Box,
  IconButton,
  LinearProgress,
  Pagination,
  PaginationItem,
  Stack,
  styled
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import QuestionItemImport from './QuestionItemImport';
import { cancelEventPropagation } from '../../../../../utils/Utils';

const LINEAR_PROGRESS = 2;
const CIRCULAR_PROGRESS = 1;

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function QuestionImport(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [errorMessages, setErrorMessages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [pages, setPages] = useState(['']);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultLimit, setDefaultLimit] = useState(10);
  const [limit, setLimit] = useState(10);
  const [pagesRight, setPagesRight] = useState(1);
  const [currentPageRight, setCurrentPageRight] = useState(1);
  const [isLoading, setIsLoading] = useState(0);
  const [paramsFilter, setParamsFilter] = useState(null);
  const [topicPath, setTopicPath] = useState('');
  const [search, setSearch] = useState('');
  const [path, setPath] = useState('');
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [question, setQuestion] = useState({});
  const dispath = useDispatch();
  const [questionIndex, setSelectQuestionIndex] = useState(null);
  const [errorIds, setErrorIds] = useState([]);

  const [subQuestions, setSubQuestions] = useState([]);

  const handleSendParams = (params) => {
    setTopicPath(params.topicPath);
    setSearch(params.search);
    setPath(params.path);
    setParamsFilter(params);
  };

  const reset = () => {
    setQuestions([]);
    setPages(['']);
    setCurrentPage(1);
    setLimit(defaultLimit);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   *  Action a éxécuter au click sur le bouton import : on envoie les ids des
   *  questions, la version et le paramètres withSubquestion qui précise si l'import
   *  se fera avec ou sans les sous questions
   */
  const doImport = () => {
    const path = `${props.metadata.country}.${props.metadata.class}.${props.metadata.subject}`;
    const topicPath = `${props.metadata.country}#${props.metadata.class}#${props.metadata.subject}#${props.metadata.topic}`;

    const questionsIds = right.map((element) => {
      return {
        questionId: element.questionId,
        version: element.version,
        withSubquestion: element.withSubquestion
      };
    });
    setIsLoading(LINEAR_PROGRESS);
    const dataToSend = {
      questionsIds,
      topicPath
    };
    const queueSnack = enqueueSnackbar('Opération en cours', {
      variant: 'info',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    });
    QuestionService.importQuestion(dataToSend, path)
      .then((result) => {
        console.log('doImport/result=', result);
        setIsLoading(0);
        closeSnackbar(queueSnack);
        enqueueSnackbar(formatMessage({ id: 'success_recorded_question' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
        const formatData = {
          type: 'import',
          data: result.data.validIds
        };
        publish('getNewQuestion', formatData);
        setRight([]);
      })
      .catch((err) => {
        setIsLoading(0);
        closeSnackbar(queueSnack);
        enqueueSnackbar(formatMessage({ id: 'import_error' }), {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
        setErrorIds(err.response.data.errorsId);
        setRight(
          right.map((question) => {
            return {
              ...question,
              hasError: err.response.data.errorsId.includes(question.questionId) ? true : false
            };
          })
        );
        const formatData = {
          type: 'import',
          data: err.response.data.validIds
        };
        publish('getNewQuestion', formatData);
        console.log('doImport/err=', err);
      });
  };

  // Récupérer la liste des sous question
  const getSubQuestions = (question, index, data) => {
    question.subQuestions = data.map((item) => itemToData(item));
    left[index] = question;
    setLeft(left);
  };

  const getQuestionRight = () => {
    const data = [];
    let index = (currentPageRight - 1) * limit;
    let count = 0;
    while (count < limit && right[index]) {
      data.push(right[index]);
      count++;
      index++;
    }
    return data;
  };
  const moveAllToLeft = () => {
    const leftData = left.map((element) => {
      element.hasMove = false;
      return element;
    });
    setLeft(leftData);
    setRight([]);
    setErrorIds([]);
    setPagesRight(1);
  };

  const moveAllToRight = () => {
    const leftData = left.filter((item) => item.hasMove === false);
    setRight(right.concat(leftData));
    setLeft(
      left.map((item) => {
        const element = {
          ...item
        };
        element.hasMove = true;
        element.withSubquestion = false;
        return element;
      })
    );
    setPagesRight(parseInt(right.length / limit) + 1);
  };

  const moveToRight = (item) => {
    const found = right.find((element) => element.questionId === item.questionId);
    if (!found) {
      setRight(right.concat(item));
      left.map((element) => {
        if (element.questionId === item.questionId) {
          element.hasMove = true;
          element.withSubquestion = item.withSubquestion;
        }
        return element;
      });
    } else {
      console.log(right);
    }

    setPagesRight(parseInt(right.length / limit) + 1);
  };

  const moveSubQuestionWithoutSubQuestions = (data) => {
    data.map((item) => {
      const newElement = itemToData(item);

      const found = right.find((element) => element.questionId === newElement.questionId);
      if (!found && newElement.hasMove === true) {
        setRight(right.concat(newElement));
      } else {
        console.log(right);
      }
      setPagesRight(parseInt(right.length / limit) + 1);
    });
  };

  const moveToLeft = (item) => {
    const leftData = left.map((element) => {
      if (element.questionId === item.questionId) {
        element.hasMove = false;
        element.withSubquestion = false;
      }
      return element;
    });
    setLeft(leftData);
    setRight(right.filter((element) => element.questionId !== item.questionId));
    setErrorIds([]);
    setPagesRight(parseInt(right.length / limit) + 1);
  };
  const handleChangePageRight = (event, value) => {
    setCurrentPageRight(value);
  };
  const handleChangePage = (event, value) => {
    // const dataPath = computePath();
    console.log('handleChangePage', value);
    setCurrentPage(value);
    setIsLoading(2);
    const params = {
      topicPath: topicPath,
      limit,
      lastEvaluatedKeyPrev: pages.at(value - 1)
    };
    console.log('handleChangePage/search = ', search);
    if (search !== null && search.trim() !== '') params.search = search;
    listQuestion(params, path);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const useHasChanged = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
  };
  const hasChangedMetadatas = useHasChanged(props.metadata);

  useEffect(() => {
    reset();
    if (paramsFilter) {
      let params = { topicPath, limit };
      if (search && search.trim().length > 0) {
        params = { ...params, search };
      }
      console.log('useEffect/search = ', search);
      listQuestion(params, paramsFilter.path);
    }
  }, [paramsFilter]);

  function listQuestion(params, path) {
    setIsLoading(LINEAR_PROGRESS);
    console.log('listQuestion/path=', path);
    params.version = 'CURRENT'; // Uniquement les currents
    params.archive = 0;
    QuestionService.listQuestions(params, path)
      .then((response) => {
        console.log('Latest pages', pages);
        console.log(response.data);
        setIsLoading(0);
        if (response.data.questions.length === 0) setLeft([]);

        if (
          response.data.questions != null &&
          Array.isArray(response.data.questions) &&
          response.data.questions.length > 0
        ) {
          setQuestions(response.data.questions.map((question) => itemToData(question)));
          setLeft(
            response.data.questions.map((item) => {
              const question = itemToData(item);
              const found = right.find((element) => element.questionId === question.questionId);
              return {
                hasMove: found ? true : false,
                ...question,
                withSubquestion: false
              };
            })
          );

          const lastEvaluatedKeyNext = response.data.lastEvaluatedKeyNext;
          if (
            lastEvaluatedKeyNext !== null &&
            lastEvaluatedKeyNext !== '' &&
            pages.indexOf(lastEvaluatedKeyNext) < 0
          ) {
            setPages((oldArray) => [...oldArray, lastEvaluatedKeyNext]);
          }
        } else {
          if (
            params.lastEvaluatedKeyPrev &&
            params.lastEvaluatedKeyPrev != null &&
            params.lastEvaluatedKeyPrev !== ''
          ) {
            // La page n'a pas envoyé de données, donc on retirer cette page de la liste.
            setPages((oldArray) => oldArray.filter((val) => val !== params.lastEvaluatedKeyPrev));
            setCurrentPage(currentPage - 1);
          }
        }
      })
      .catch((err) => {
        setIsLoading(0);
        setQuestions([]);

        setLeft([]);
        console.log('List data error');
      });
  }

  function publish(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data });
    console.log(eventName);
    document.dispatchEvent(event);
  }
  const customListLeft = (items) => (
    <Box>
      {isLoading === LINEAR_PROGRESS && (
        <Box sx={{ pt: '10px', width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {isLoading === CIRCULAR_PROGRESS && <AppProgress />}

      {items.length > 0 && (
        <Box sx={{ padding: '0 10px', overflow: 'auto', bgcolor: 'white' }}>
          <List dense component="div" role="list">
            {items.map((question, index) => {
              const labelId = `transfer-list-item-${index}-label`;
              return (
                <QuestionItemImport
                  metadata={props.metadata}
                  question={question}
                  root={question}
                  onSetSubQuestions={(data) => getSubQuestions(question, index, data)}
                  importSubQuestion={(data) => moveSubQuestionWithoutSubQuestions(data)}
                  index={index}
                  key={index}
                  actions={
                    <Button
                      sx={{ ml: 1, width: '5px' }}
                      variant="outlined"
                      size="small"
                      onClick={(event) => {
                        cancelEventPropagation(event);
                        moveToRight(question);
                      }}
                      disabled={question.hasMove}
                      aria-label="move selected right">
                      <ArrowForwardIosOutlined fontSize="small" />
                    </Button>
                  }
                />
              );
            })}
          </List>
          <div style={{ padding: '10px 0' }} className="layout horizontal end-justified">
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                moveAllToRight();
              }}
              aria-label="move selected right">
              {formatMessage({ id: 'send_all' })} ≫
            </Button>
          </div>
          <Box sx={{ mb: 1 }}>
            {questions.length > 0 && pages.length > 0 && (
              <div className="layout horizontal center end-justified">
                <Stack spacing={2}>
                  <Pagination
                    color="primary"
                    showFirstButton
                    showLastButton
                    count={pages.length}
                    page={currentPage}
                    onChange={handleChangePage}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </div>
            )}
          </Box>
        </Box>
      )}
      {items.length === 0 && (
        <BoxNoDataFound>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Box
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                border: '1px solid #F9F9F9',
                backgroundColor: '#F9F9F9',
                color: '#c8c8c8',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <FilterListOffRounded />
            </Box>
            <div
              style={{
                color: '#c8c8c8',
                width: '100%',
                textAlign: 'center',
                paddingTop: '5px'
              }}>
              <p style={{ lineHeight: '3px', fontWeight: 'bold' }}>
                {formatMessage({ id: 'no_data_found' })}
              </p>
              <p style={{ fontSize: '13px' }}>{formatMessage({ id: 'use_the_filter' })}</p>
            </div>
          </Box>
        </BoxNoDataFound>
      )}
    </Box>
  );
  const customListRight = (items) => (
    <Box sx={{ overflow: 'auto', bgcolor: 'white' }}>
      {errorIds.length === 1 && (
        <Box>{formatMessage({ id: 'unimported_questions' }, { count: errorIds.length })}</Box>
      )}
      {errorIds.length > 1 && (
        <Box p={1} sx={{ textAlign: 'center', fontWeight: 400 }}>
          {formatMessage({ id: 'unimported_questions' }, { count: errorIds.length })}{' '}
        </Box>
      )}
      <List dense component="div" role="list">
        {items.map((question, i) => {
          const labelId = `transfer-list-item-${i}-label`;
          return (
            <ListItem
              dense
              role="listitem"
              sx={{
                padingLeft: '5px',
                display: 'flex',
                flexWrap: 'nowrap',
                cursor: 'pointer'
              }}
              key={question.questionId}>
              <ListItemText
                id={labelId}
                primary={
                  <Stack direction="row" sx={{ alignItems: 'baseline' }}>
                    <Box
                      sx={{
                        fontSize: question.version === EnumVersion.SUBQUESTION ? '14px' : '15px',
                        fontStyle:
                          question.version === EnumVersion.SUBQUESTION ? 'italic' : 'normal'
                      }}>
                      {question.content}
                    </Box>
                    <div>
                      {question.withSubquestion && (
                        <IconButton
                          color="primary"
                          aria-label={formatMessage({ id: 'import_with_subquestion2' })}
                          title={formatMessage({ id: 'import_with_subquestion2' })}>
                          <Info color="primary" />
                        </IconButton>
                      )}
                    </div>
                  </Stack>
                }
                onClick={(e) => {
                  console.log(question);
                }}
                sx={{
                  color: question.hasError ? 'red' : 'black'
                }}
              />
              <IconButton
                sx={{ ml: 3, width: 2 }}
                onClick={() => moveToLeft(question)}
                color="secondary"
                title={formatMessage({ id: 'remove_this_question' })}
                aria-label={formatMessage({ id: 'remove_this_question' })}
                size="small">
                <RemoveCircle />
              </IconButton>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Button
          sx={{ mb: 3 }}
          variant="outlined"
          size="small"
          onClick={() => {
            moveAllToLeft();
          }}
          aria-label="move selected right">
          {formatMessage({ id: 'remove_all' })} <RemoveCircle sx={{ ml: 1 }} />
        </Button>
        <Button
          sx={{ ml: 1, mb: 3 }}
          variant="contained"
          size="small"
          onClick={() => {
            doImport();
          }}
          aria-label="move selected right"
          color="success">
          {formatMessage({ id: 'import' })} <ImportExport sx={{ ml: 1 }} />
        </Button>
      </div>
      <Box sx={{ mb: 1 }}>
        {right.length > 0 && pagesRight > 0 && (
          <div className="layout horizontal center end-justified">
            <Stack spacing={2}>
              <Pagination
                color="primary"
                showFirstButton
                showLastButton
                count={pagesRight}
                page={currentPageRight}
                onChange={handleChangePageRight}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        )}
      </Box>
    </Box>
  );
  const BoxNoDataFound = styled(Box)({
    backgroundColor: 'white',
    padding: '15px',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });
  console.log('QuestionImport/metadata = ', props.metadata);
  return (
    <>
      <Paper elevation={0} sx={{ backgroundColor: '#FAFAFA', borderRadius: '5px' }}>
        <Box sx={{ bgcolor: 'white', p: '10px', borderRadius: '5px' }}>
          <AppTitle
            icon={<ImportExport />}
            title={formatMessage({ id: 'import_questions' })}
            subtitle={formatMessage({ id: 'use_the_filter' })}
          />
        </Box>
        <Box sx={{ mt: '5px', mb: '5px', bgcolor: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <FilterData onSetParams={handleSendParams} />
          </div>
        </Box>
        <Box sx={{ pl: '5px', pr: '5px', pb: '5px' }}>
          <div style={{ bgcolor: 'white' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sx={{ bgcolor: 'transparent' }}>
                {customListLeft(left)}
              </Grid>
              {right.length > 0 && (
                <Grid item xs={6}>
                  {customListRight(getQuestionRight())}
                </Grid>
              )}
              {right.length === 0 && (
                <Grid item xs={6}>
                  <BoxNoDataFound>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <Box
                        sx={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          border: '1px solid #F9F9F9',
                          backgroundColor: '#F9F9F9',
                          color: '#c8c8c8',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <ScienceRounded />
                      </Box>
                      <div
                        style={{
                          color: '#c8c8c8',
                          width: '100%',
                          textAlign: 'center',
                          paddingTop: '5px'
                        }}>
                        <p style={{ lineHeight: '3px', fontWeight: 'bold' }}>
                          {formatMessage({ id: 'no_question_selected' })}
                        </p>
                        <p style={{ fontSize: '13px' }}>
                          {formatMessage({ id: 'selected_question_left_pane' })}
                        </p>
                      </div>
                    </Box>
                  </BoxNoDataFound>
                </Grid>
              )}
            </Grid>
          </div>
        </Box>
      </Paper>
    </>
  );
}

export default QuestionImport;
