import { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import QuestionItemGeneric from '../../../../../components/shared/question/QuestionItemGeneric';
import { cancelEventPropagation } from '../../../../../utils/Utils';

function QuestionItem(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [open, setOpen] = useState(false);
  const [openStatusChange, setOpenStatusChange] = useState(false);
  // New add
  const [statusChange, setStatusChange] = useState('');

  const onConfirmDelete = (event) => {
    setOpen(false);
    if (props.onDelete) props.onDelete();
  };

  const onConfirmUnArchive = (event) => {
    console.log('UNARCHIVING ***********');
    setOpenStatusChange(false);
    if (props.onUnArchive) props.onUnArchive();
  };

  const onClickUnArchive = (event) => {
    console.log('ouverture du popup pour confirmation du Unarchive.');
    cancelEventPropagation(event);
    // TODO: ouvrir le popup de confirmation.
    setOpenStatusChange(true);
    setStatusChange('UNARCHIVE');
  };

  const onClickDelete = (event) => {
    console.log('ouverture du popup pour confirmation de suppression.');
    cancelEventPropagation(event);
    // TODO: ouvrir le popup de confirmation.
    setOpen(true);
  };

  const onClickAcceptChanges = (event) => {
    console.log('ouverture du popup pour confirmation de AcceptChanges.');
    cancelEventPropagation(event);
    // TODO: ouvrir le popup de confirmation.
    setOpenStatusChange(true);
    setStatusChange('ACCEPT');
  };

  const onClickDiscardChanges = (event) => {
    console.log('ouverture du popup pour confirmation de DiscardChanges.');
    cancelEventPropagation(event);
    // TODO: ouvrir le popup de confirmation.
    setOpenStatusChange(true);
    setStatusChange('DISCARD');
  };

  const onClickEnabled = (event) => {
    if (props.onChangeStatus) props.onChangeStatus(1);
  };
  const onClickDisabled = (event) => {
    if (props.onChangeStatus) props.onChangeStatus(0);
  };
  const onClickEdit = (event) => {
    if (props.onEdit) props.onEdit();
  };

  // const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenStatusChange(false);
  };

  // New add
  const onConfirmAcceptChanges = (event) => {
    console.log('AcceptChanges.');
    setOpenStatusChange(false);
    if (props.onAcceptChanges) props.onAcceptChanges();
  };
  const onConfirmDiscardChanges = (event) => {
    console.log('DiscardChanges.');
    setOpenStatusChange(false);
    if (props.onDiscardChanges) props.onDiscardChanges();
  };

  const { index, question } = props;

  const canShowNotifButton = () => {
    console.log(question.isDeleted);
    return (
      !question.isDeleted &&
      ((question.notif && props.parentProps.params.notif == '1') ||
        (question.notif &&
          props.parentProps.params.notif == 0 &&
          question.notif &&
          props.parentProps.params.notif == 0))
    );
  };

  const canShowUnarchivedButton = () => {
    return question.isDeleted && props.parentProps.params.archive == '1';
  };

  return (
    <>
      <QuestionItemGeneric
        question={props.question}
        index={props.index}
        actions={
          <div className="layout horizontal center">
            {canShowNotifButton() && (
              <Button
                variant="contained"
                color="success"
                title={formatMessage({ id: 'apply_change_on_family' })}
                size="small"
                onClick={onClickAcceptChanges}>
                {formatMessage({ id: 'accept_changes' })}
              </Button>
            )}

            {canShowNotifButton() && (
              <Button
                variant="contained"
                color="error"
                title="Dissocier les liens avec ma famille"
                size="small"
                style={{ marginLeft: '6px' }}
                onClick={onClickDiscardChanges}>
                {formatMessage({ id: 'accept_changes' })}
                {formatMessage({ id: 'discard_changes' })}
              </Button>
            )}

            {canShowUnarchivedButton() && (
              <Button
                variant="contained"
                onClick={onClickUnArchive}
                size="small"
                color="success"
                title={formatMessage({
                  id: 'question_archived_click_to_desarchive'
                })}
                style={{ marginLeft: '6px' }}>
                {formatMessage({ id: 'unarchive' })}
              </Button>
            )}

            {canShowUnarchivedButton() && (
              <Button
                variant="contained"
                onClick={onClickDelete}
                size="small"
                color="error"
                title={formatMessage({ id: 'permant_delete' })}
                style={{ marginLeft: '6px' }}>
                {formatMessage({ id: 'delete' })}
              </Button>
            )}
          </div>
        }
      />

      {/* Dialog Confirm DELETE or ARCHIVE question */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'confirm_delete' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formatMessage({ id: 'confirm_delete' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {formatMessage({ id: 'yes' })}, {formatMessage({ id: 'cancel' })}
          </Button>
          <Button onClick={onConfirmDelete} autoFocus>
            {formatMessage({ id: 'yes' })}, {formatMessage({ id: 'delete' })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Confirm ACCEPT CHANGE and DISCARD CHANGES */}
      <Dialog
        open={
          ['ACCEPT', 'DISCARD', 'UNARCHIVE', 'BACK'].includes(statusChange)
            ? openStatusChange
            : false
        }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'confirm_action' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formatMessage({ id: 'Do_you_really_want' })}
            {statusChange === 'UNARCHIVE'
              ? formatMessage({ id: 'unarchive_question' })
              : formatMessage({ id: 'perform_action' })}{' '}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {formatMessage({ id: 'yes' })}, {formatMessage({ id: 'cancel' })}
          </Button>
          <Button
            onClick={
              statusChange === 'ACCEPT'
                ? onConfirmAcceptChanges
                : statusChange === 'DISCARD'
                ? onConfirmDiscardChanges
                : onConfirmUnArchive
            }
            autoFocus>
            {formatMessage({ id: 'yes' })}, {formatMessage({ id: 'apply' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default QuestionItem;
