import { createSlice } from '@reduxjs/toolkit';
import EnumQuestionType from '../../utils/EnumQuestionType';
import { convertTopicPathToMetadata } from '../../utils/Utils';

const initialState = { type: 'OPEN', mark: '1', duration: '1' };

const editQuestionSlice = createSlice({
  name: 'editQuestion',
  initialState: initialState,
  reducers: {
    setGeneric: (state, action) => {
      return { ...state, ...action.payload };
    },

    setMulti: (state, action) => {
      return { ...state, multi: { ...action.payload } };
    },

    setOpen: (state, action) => {
      console.log('setOpen/action = ', action);
      return { ...state, open: { ...action.payload } };
    },
    setSingle: (state, action) => {
      return { ...state, single: { ...action.payload } };
    },
    setColumn: (state, action) => {
      return { ...state, column: { ...action.payload } };
    },
    setTextMatch: (state, action) => {
      return { ...state, textMatch: { ...action.payload } };
    },
    resetData: (state) => {
      return { type: 'OPEN', mark: '1', duration: '1' };
    }
  }
});

export const { setGeneric, setMulti, setOpen, setSingle, setColumn, setTextMatch, resetData } =
  editQuestionSlice.actions;

export const selectEditQuestion = (state) => state.editQuestion;
export const selectMulti = (state) => (state.editQuestion != null ? state.editQuestion.multi : {});
export const selectSingle = (state) =>
  state.editQuestion != null ? state.editQuestion.single : {};
export const selectOpen = (state) => (state.editQuestion != null ? state.editQuestion.open : {});
export const selectGeneric = (state) => {
  if (state.editQuestion == null) return {};
  return (({ open, single, multi, column, textMatch, ...o }) => o)(state.editQuestion);
};

export const selectFormatQuestion = (state) => {
  let question = selectGeneric(state);
  switch (question.type) {
    case EnumQuestionType.OPEN: {
      const open = selectOpen(state);
      question = { ...question, ...open };
      break;
    }
    case EnumQuestionType.MULTI_CHOICE: {
      const multi = selectMulti(state);
      question = { ...question, ...multi };
      break;
    }
    case EnumQuestionType.SINGLE_CHOICE: {
      const single = selectSingle(state);
      question = { ...question, ...single };
      break;
    }
  }
  return question;
};
export const selectMetadata = (state) =>
  state.editQuestion.topicPath && state.editQuestion.topicPath != null
    ? convertTopicPathToMetadata(state.editQuestion.topicPath)
    : null;

export const editQuestionReducer = editQuestionSlice.reducer;
