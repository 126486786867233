import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './slices/userSlice';
import conReducer from './slices/connectionSlice';
import { newQuestionReducer } from './slices/newQuestionSlice';
import { editQuestionReducer } from './slices/editQuestionSlice';
import { subQuestionReducer } from './slices/subQuestionSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    con: conReducer,
    newQuestion: newQuestionReducer,
    editQuestion: editQuestionReducer,
    subQuestion: subQuestionReducer
  }
});
