import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Logout, Notifications } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, selectUser } from '../stores/slices/userSlice';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { setCon } from '../stores/slices/connectionSlice';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import { useIntl } from 'react-intl';

import logo from '../assets/Learndia_2.png';
import { Link } from 'react-router-dom';

const AppToolBar = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  /* eslint-disable-next-line no-unused-vars */
  const [cookies, setCookie] = useCookies(['user']);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const logout = () => {
    handleCloseUserMenu();
    console.log('déconnection ..');
    dispatch(clearUser());
    // TODO: clear d'autres stores pour l'utilisateur.
    setCookie('token', null, { path: '/' });
    dispatch(setCon(false));
    navigate('/user/login', { replace: true });
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar className="layout horizontal" disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}

          <Link
            style={{ textDecoration: 'none' }}
            to={user && user.role == 'ADMIN' ? '/admin' : '/space'}>
            <Box
              component="img"
              sx={{
                display: { xs: 'none', md: 'flex' },
                height: 48,
                width: 48
              }}
              alt="Logo."
              src={logo}
            />
          </Link>

          <div className="flex"></div>

          {user && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={formatMessage({ id: 'profile' })}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/profil_photo/photo.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '40px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuList sx={{ pt: 0, pb: 0 }}>
                  <MenuItem>
                    <ListItemIcon>
                      <PersonIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText sx={{ textTransform: 'capitalize' }}>
                      <b>{user.pseudo}</b>
                    </ListItemText>
                  </MenuItem>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={user.role == 'ADMIN' ? '/admin' : '/space'}>
                    <MenuItem>
                      <ListItemIcon>
                        <KeyIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText sx={{ textTransform: 'uppercase' }}>
                        <Typography variant="span" component="small" color="primary">
                          {user.role}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={user.role == 'ADMIN' ? '/admin/notif' : '/space/notif'}>
                    <MenuItem>
                      <ListItemIcon>
                        <Notifications color="primary" />
                      </ListItemIcon>
                      <ListItemText sx={{ textTransform: 'uppercase' }}>
                        <Typography variant="span" component="small" color="primary">
                          {formatMessage({ id: 'notification' })}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </Link>

                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <Logout color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ textTransform: 'capitalize' }}>
                      {formatMessage({ id: 'logout' })}
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppToolBar;
