import axios from 'axios';
import KEYS from '../../../utils/key';
import { Component } from 'react';
import CookieService from '../../../cookieService';
import { computeXAccessRessource } from './utils';

class UploadService extends Component {
  upload(img, path) {
    console.log('starting upload service file:');
    const formData = new FormData();
    formData.append('file', img);
    formData.append('path', path);
    return axios.post(KEYS.backendPath + '/quiz/image/upload-image', formData, {
      headers: this.authHeader()
    });
  }

  delete(path, imageId) {
    console.log('starting delete image service');
    const headers = this.authHeader();
    headers['x-resource'] = btoa(computeXAccessRessource(path));
    console.log('headers ', headers);
    return axios.delete(
      KEYS.backendPath + '/quiz/image/delete-image/' + encodeURIComponent(imageId),
      { headers: headers }
    );
  }

  authHeader(path) {
    const token = CookieService.getToken();
    if (token) {
      // for Node.js Express back-end
      return {
        'x-access-token': token,
        'Content-Type': 'multipart/form-data'
      };
    } else {
      return {};
    }
  }
}

export default new UploadService();

// const authHeader = () => {
//   const token = CookieService.getToken();
//   if (token) {
//     // for Node.js Express back-end
//     return {
//       'x-access-token': token,
//       'Content-Type': 'multipart/form-data'
//     };
//   } else {
//     return {};
//   }
// };

// const upload = async (img, path) => {
//   console.log('starting upload service file:');
//   const formData = new FormData();
//   // formData.append('file', img);
//   // formData.append('path', path);
//   formData.file = img;
//   formData.path = path;
//   // return axios.post(KEYS.backendPath + '/quiz/image/upload-image', formData, {
//   //   headers: this.authHeader()
//   // });
//   const url = KEYS.backendPath + '/quiz/image/upload-image';
//   const headers = authHeader();

//   console.log('HEADERS ================ ', headers);
//   console.log('FormData ================ ', formData);

//   const response = await axios.post(url, formData, { headers: headers });

//   console.log('Response ', response);

//   const blob = formData.file.buffer;

//   const result = await fetch(response.uploadURL, {
//     method: 'PUT',
//     body: blob
//   });

//   console.log('Result ', result);

//   return response.uploadURL;
// };

// const deleteImage = (path, imageId) => {
//   console.log('starting delete image service');
//   const headers = authHeader();
//   headers['x-resource'] = btoa(computeXAccessRessource(path));
//   console.log('headers ', headers);
//   const response = axios.delete(
//     KEYS.backendPath + '/quiz/image/delete-image/' + encodeURIComponent(imageId),
//     { headers: headers }
//   );
//   return response;
// };

// const UploadService = {
//   upload,
//   deleteImage
// };

// export default UploadService;

// class UploadService extends Component {
//   async upload(img, path) {
//     console.log('starting upload service file:');
//     const formData = new FormData();
//     formData.append('file', img);
//     formData.append('path', path);
//     // return axios.post(KEYS.backendPath + '/quiz/image/upload-image', formData, {
//     //   headers: this.authHeader()
//     // });
//     const url = KEYS.backendPath + '/quiz/image/upload-image';
//     const headers = this.authHeader();

//     console.log('HEADERS ================ ', headers);

//     const response = await axios.post(url, { formData, headers: headers });

//     console.log('Response ', response);

//     const blob = formData.file.buffer;

//     const result = await fetch(response.uploadURL, {
//       method: 'PUT',
//       body: blob
//     });

//     console.log('Result ', result);

//     return response.uploadURL;
//   }

//   async delete(path, imageId) {
//     console.log('starting delete image service');
//     const headers = this.authHeader();
//     headers['x-resource'] = btoa(computeXAccessRessource(path));
//     console.log('headers ', headers);
//     const response = await axios.delete(
//       KEYS.backendPath + '/quiz/image/delete-image/' + encodeURIComponent(imageId),
//       { headers: headers }
//     );
//     return response;
//   }

//   authHeader(path) {
//     const token = CookieService.getToken();
//     if (token) {
//       // for Node.js Express back-end
//       return {
//         'x-access-token': token,
//         'Content-Type': 'multipart/form-data'
//       };
//     } else {
//       return {};
//     }
//   }
// }

// export default new UploadService();
