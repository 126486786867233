import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CustomizedMenus from './CustomizedMenu';
import KEYS from '../../utils/key';

function Footer() {
  const languages = [
    { name: 'Français', code: 'FR', country_code: 'fr', value: 'fr', icon: '/flags/fr.svg' },
    { name: 'Anglais', code: 'EN', country_code: 'gb', value: 'en', icon: '/flags/gb.svg' }
  ];

  const [language, setLanguage] = useState({
    name: 'Français',
    code: 'FR',
    country_code: 'fr',
    value: 'fr',
    icon: '/flags/fr.svg'
  });
  const chooseLanguage = (e) => {
    setLanguage(e);

    publishEvent('language-change', e);
  };
  function publishEvent(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
  }
  useEffect(() => {
    const userLocal = localStorage.getItem(KEYS.userLocale) || 'fr';
    if (userLocal === 'en') {
      setLanguage({
        name: 'English',
        code: 'EN',
        country_code: 'gb',
        value: 'en',
        icon: '/flags/gb.svg'
      });
    } else if (userLocal === 'fr') {
      setLanguage({
        name: 'Français',
        code: 'FR',
        country_code: 'fr',
        value: 'fr',
        icon: '/flags/fr.svg'
      });
    }
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <AppBar position="static" elevation={0}>
        <Toolbar sx={{ backgroundColor: '#eeeeee' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}>
            <Box
              variant="h6"
              component="div"
              sx={{
                fontWeight: 'none',
                fontSize: '14px',
                color: 'black',
                mr: 3
              }}>
              &copy;2022 Studa Inc. All rights reserved. Douala-Cameroon.
            </Box>
            <Box>
              <CustomizedMenus
                languages={languages}
                onSelectLanguage={(e) => {
                  chooseLanguage(e);
                }}
                language={language}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Footer;
