import { Collapse, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { setGeneric, selectGeneric } from '../../../../../stores/slices/editQuestionSlice';
import { Done, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import FileUploaderMultiple from '../../../../../components/shared/FileUploader/FileUploaderMultiple';
import { useIntl } from 'react-intl';

function QuestionEditGeneric(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const generic = useSelector(selectGeneric);
  const dispatch = useDispatch();

  const { question } = props;
  const [content, setContent] = useState(question?.content ?? '');
  const [mark, setMark] = useState(question?.mark ?? '1');
  const [duration, setDuration] = useState(question?.duration ?? '1');
  const [type, setType] = useState(question?.type ?? 'OPEN');
  const [images, setImages] = useState(question?.images ?? []);

  const [showEditMark, setShowEditMark] = useState(false);
  const [showEditDuration, setShowEditDuration] = useState(false);
  const [showImageZone, setShowImageZone] = useState(false);
  const [error, setError] = useState('');
  const [path, setPath] = useState(
    `/${props.metadata.country}/${props.metadata.class}/${props.metadata.subject}/${props.metadata.topic}`
  );

  const imageUploaderRef = useRef();

  const handleChange = (prop) => (event) => {
    console.log('prop', prop);
    console.log(event.target.value);
    if (prop == 'content') {
      setContent(event.target.value);
      dispatch(setGeneric({ content: event.target.value }));
    } else if (prop == 'mark') {
      setMark(event.target.value);
      dispatch(setGeneric({ mark: event.target.value }));
    } else if (prop == 'duration') {
      setDuration(event.target.value);
      dispatch(setGeneric({ duration: event.target.value }));
    } else if (prop == 'type') {
      setType(event.target.value);
      dispatch(setGeneric({ type: event.target.value }));
    }
  };

  const onFireImages = (imgs) => {
    console.log('Onfire image', imgs);
    setImages((images) => [...imgs]);
    dispatch(setGeneric({ images: [...imgs] }));
  };

  useEffect(() => {
    if (props.metadata != null) {
      setPath(
        `/${props.metadata.country}/${props.metadata.class}/${props.metadata.subject}/${props.metadata.topic}`
      );
    } else {
      setPath('');
    }
  }, [props.metadata]);
  function getError(errors, code) {
    const error = errors.find((err) => err.code === code);
    return error;
  }

  return (
    <>
      <div style={{ marginTop: '10px' }} className="layout vertical">
        {/* props.errorMessages.length > 0 && (
          <div className="error" style={{ color: "red" }}>
            {getError(props.errorMessages, "content") &&
              getError(props.errorMessages, "content").message}
          </div>
        )*/}
        <div className="layout horizontal">
          <div className="flex-8 layout horizontal">
            <TextField
              disabled={props.submitting || (props.action && props.action === 'detail')}
              className="flex"
              size="small"
              label={formatMessage({ id: 'question_content' })}
              placeholder={formatMessage({ id: 'question_content' })}
              multiline
              maxRows={4}
              minRows={3}
              value={content}
              sx={{ mb: 1.5 }}
              onChange={handleChange('content')}
            />
          </div>
          <div style={{ paddingLeft: 15 }} className="layout vertical flex-5">
            <div className="layout horizontal">
              {!showEditMark && (
                <div style={{ paddingTop: 5 }}>
                  {/* <Button onClick={(event) => setShowEditMark(true)} title="Modifier" sx={{ textTransform: "none" }} size="medium" variant="text" endIcon={<KeyboardArrowDown />}>{mark}&nbsp;Point(s)</Button> */}
                  <Link
                    title={formatMessage({ id: 'edit_marks' })}
                    underline="none"
                    component="button"
                    variant="body2"
                    onClick={() => setShowEditMark(true)}>
                    {' '}
                    <span className="layout horizontal center">
                      <KeyboardArrowDown />
                      <span>
                        {mark} {formatMessage({ id: 'marks' })}(s)
                      </span>{' '}
                    </span>
                  </Link>
                </div>
              )}

              {showEditMark && (
                <div className="layout horizontal">
                  <TextField
                    disabled={props.submitting || (props.action && props.action === 'detail')}
                    size="small"
                    label={formatMessage({ id: 'nomber_of_marks' })}
                    type="number"
                    maxRows={4}
                    value={mark}
                    onChange={handleChange('mark')}
                    sx={{ mb: 1.5, height: 28 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {formatMessage({ id: 'marks' })}(s)
                        </InputAdornment>
                      ),
                      inputProps: {
                        pattern: '[0-9]*',
                        inputMode: 'numeric',
                        min: 0
                      }
                    }}
                  />
                  <IconButton
                    onClick={(event) => setShowEditMark(false)}
                    sx={{ ml: 1 }}
                    color="primary"
                    title={formatMessage({ id: 'validate_or_quit' })}
                    aria-label={formatMessage({ id: 'validate_or_quit' })}>
                    <Done />
                  </IconButton>
                </div>
              )}
            </div>

            <div className="layout horizontal">
              {!showEditDuration && (
                <div style={{ paddingTop: 5 }}>
                  {/* <Button onClick={(event) => setShowEditDuration(true)} title="Modifier" sx={{ textTransform: "none" }} size="medium" variant="text" endIcon={<KeyboardArrowDown />}>{duration}&nbsp;Minute(s)</Button> */}
                  <Link
                    title={formatMessage({ id: 'edit_duration' })}
                    underline="none"
                    component="button"
                    variant="body2"
                    onClick={() => setShowEditDuration(true)}>
                    {' '}
                    <span className="layout horizontal center">
                      <KeyboardArrowDown />
                      <span>
                        {duration} {formatMessage({ id: 'minuts' })}(s)
                      </span>{' '}
                    </span>
                  </Link>
                </div>
              )}
              {showEditDuration && (
                <div className="layout horizontal">
                  <TextField
                    disabled={props.submitting || (props.action && props.action === 'detail')}
                    size="small"
                    label={formatMessage({ id: 'duration_in_minut' })}
                    type="number"
                    min={0}
                    maxRows={4}
                    sx={{ mt: '5px', mb: 1.5, height: 28 }}
                    value={duration}
                    onChange={handleChange('duration')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {formatMessage({ id: 'minuts' })}(s)
                        </InputAdornment>
                      ),
                      inputProps: {
                        pattern: '[0-9]*',
                        inputMode: 'numeric',
                        min: 0
                      }
                    }}
                  />
                  <IconButton
                    onClick={(event) => setShowEditDuration(false)}
                    sx={{ ml: 1 }}
                    color="primary"
                    title={formatMessage({ id: 'validate_or_quit' })}
                    aria-label={formatMessage({ id: 'validate_or_quit' })}>
                    <Done />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="layout vertical">
          <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
              if (showImageZone) setShowImageZone(false);
              else setShowImageZone(true);
            }}>
            {showImageZone && (
              <Stack className="layout horizontal center">
                {' '}
                <div className="layout horizontal center">
                  {' '}
                  <KeyboardArrowUp />
                  <span>
                    {formatMessage({ id: 'hide_image' })}({images ? images.length : 0})
                  </span>
                </div>{' '}
              </Stack>
            )}
            {!showImageZone && (
              <Stack className="layout horizontal center">
                <div className="layout horizontal center">
                  <KeyboardArrowDown />
                  <span>
                    {formatMessage({ id: 'add_image' })}({images ? images.length : 0})
                  </span>
                </div>
              </Stack>
            )}
          </Link>
          <Collapse orientation="vertical" in={showImageZone}>
            <FileUploaderMultiple
              ref={imageUploaderRef}
              initialImages={question?.images ?? []}
              disabled={props.submitting || (props.action && props.action === 'detail')}
              onFire={onFireImages}
              path={path}
            />
          </Collapse>
        </div>
        {/* <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
              const uploader = imageUploaderRef.current;
              console.log("imageUploaderRef.current",imageUploaderRef.current);
              if(uploader != null && uploader.clearAll){
                uploader.clearAll();
              }
            }}>Clear</Link> */}
        {props.errorMessages.length > 0 && (
          <div className="error" style={{ color: 'red' }}>
            {getError(props.errorMessages, 'TYPE_QUESTION_NOT_FOUND') &&
              getError(props.errorMessages, 'TYPE_QUESTION_NOT_FOUND').message}
          </div>
        )}
        <div className="layout horizontal center flex">
          <FormLabel
            sx={{ fontSize: '12px', fontWeight: 'bold' }}
            id="demo-row-radio-buttons-group-label">
            {formatMessage({ id: 'type_of_question' })}
          </FormLabel>

          <FormControl
            style={{ marginLeft: '10px' }}
            disabled={props.submitting || (props.action && props.action === 'detail')}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange('type')}
              value={type}>
              <FormControlLabel
                value="OPEN"
                control={<Radio size="small" />}
                label={
                  <span style={{ fontSize: '12px' }}>
                    {formatMessage({ id: 'open_answer_question' })}
                  </span>
                }
              />
              <FormControlLabel
                value="MULTI_CHOICE"
                control={<Radio size="small" />}
                label={<span style={{ fontSize: '12px' }}>{formatMessage({ id: 'MCQ' })}</span>}
              />
              <FormControlLabel
                value="SINGLE_CHOICE"
                control={<Radio size="small" />}
                label={
                  <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'single_choice' })}</span>
                }
              />
              {/* <FormControlLabel value="COLUMN" control={<Radio size="small" />} label="Joindre les colonnes" />
                                <FormControlLabel value="TEXT_MATCH" control={<Radio size="small" />} label="Correspondance" /> */}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
}

export default QuestionEditGeneric;
