import Page404 from '../../components/shared/Page404';
import QuestionPage from './pages/QuestionPage';
import AppMenu from '../../components/shared/AppMenu';
import { StyledEngineProvider } from '@mui/material/styles';
import ListContriesBar from '../../components/shared/ListCountriesBar';
import AppBreadCrumbs from '../../components/shared/AppBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectUser, setSelectedCountry } from '../../stores/slices/userSlice';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import AppProgress from '../../components/shared/AppProgress';
import Footer from '../../components/shared/Footer';
import { Box, Paper } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import QuestionList from '../admin/components/question/list/QuestionList';
import FilterData from './../../components/shared/FilterData';

function NotifPage() {
  const [paramsFilter, setParamsFilter] = useState(null);
  const intl = useIntl();
  const { formatMessage } = intl;

  const handleSendParams = (params) => {
    console.log(params);
    setParamsFilter(params);
  };
  return (
    <div className="layout vertical">
      <Paper elevation={0} sx={{ p: '10px', mt: '10px' }}>
        <div style={{ padding: '15px 0' }}>
          <FilterData onSetParams={handleSendParams} />
        </div>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          mt: '10px',
          border: (theme) => `0px solid ${theme.palette.divider}`
        }}>
        <QuestionList
          params={{
            ...paramsFilter,
            isTeacherNotif: true,
            version: 'NOTIF',
            archive: '0',
            title: formatMessage({ id: 'list_of_notifications' }),
            subtitle: formatMessage({ id: 'see_all_notifications' })
          }}
        />
      </Paper>
    </div>
  );
}
function SpaceModule() {
  return (
    <Routes>
      <Route path="/notif" element={<NotifPage />} />
      <Route path="*" element={<SpaceQuestion />} />
    </Routes>
  );
}
export default SpaceModule;

function SpaceQuestion() {
  const intl = useIntl();

  const { formatMessage } = intl;

  const location = useLocation();

  const [isURLCorrect, setIsURLCorrect] = useState(false);
  const [isProgress, setIsProgress] = useState(true);
  const [hasCountry, setHasCountry] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const [message, setMessage] = useState({});
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    const splitt = location.pathname.split('/');
    const locationCountryCode =
      splitt.length > 2 ? location.pathname.split('/')[2].toUpperCase() : null;

    setHasCountry(locationCountryCode != null);
    if (locationCountryCode != null && locationCountryCode != '') {
      const country = user.countries[locationCountryCode];
      dispatch(setSelectedCountry(country != null ? country : null));
      if (country != null) {
        dispatch(setSelectedCountry(country));
        const _classCode = splitt.length > 3 ? splitt[3].toUpperCase() : null;
        if (_classCode != null && _classCode != '') {
          const _class = country.classes[_classCode];
          if (_class != null) {
            const subjectCode = splitt.length > 4 ? splitt[4].toUpperCase() : null;
            if (subjectCode != null && subjectCode != '') {
              const subject = _class.subjects[subjectCode];
              if (subject != null) {
                const topicCode = splitt.length > 5 ? splitt[5].toUpperCase() : null;
                if (topicCode != null && topicCode != '') {
                  const topic = subject.topics[topicCode];
                  if (topic != null) {
                    setMetadata({
                      country: locationCountryCode,
                      class: _classCode,
                      subject: subjectCode,
                      topic: topicCode
                    });
                    setIsURLCorrect(true);
                  } else {
                    setIsURLCorrect(false); // affiche 404
                    setMessage({
                      code: '404',
                      type: 'topic_code_not_found',
                      subtitle: formatMessage({ id: 'topic_not_found' }),
                      content: formatMessage(
                        { id: 'topic_code_not_found' },
                        { topicCode: topicCode }
                      )
                    });
                  }
                } else {
                  setIsURLCorrect(false); // affiche le composant de choix
                  setMessage({
                    code: '400',
                    type: 'select_topic',
                    subtitle: formatMessage({ id: 'topic_not_found' }),
                    content: formatMessage({ id: 'select_topic' })
                  });
                }
              } else {
                setIsURLCorrect(false); // affiche 404
                setMessage({
                  code: '404',
                  type: 'subject_code_not_found',
                  subtitle: formatMessage({ id: 'subject_not_found' }),
                  content: formatMessage(
                    { id: 'subject_code_not_found' },
                    { subjectCode: subjectCode }
                  )
                });
              }
            } else {
              setIsURLCorrect(false); // affiche le composant de choix
              setMessage({
                code: '400',
                type: 'select_subject',
                subtitle: formatMessage({ id: 'subject_not_found' }),
                content: formatMessage({ id: 'select_subject' })
              });
            }
          } else {
            setIsURLCorrect(false); // affiche le 404
            setMessage({
              code: '404',
              type: 'class_code_not_found',
              subtitle: formatMessage({ id: 'class_not_found' }),
              content: formatMessage({ id: 'class_code_not_found' }, { classCode: _classCode })
            });
          }
        } else {
          setIsURLCorrect(false); // affiche le composant de choix
          setMessage({
            code: '400',
            type: 'select_class',
            subtitle: formatMessage({ id: 'class_not_found' }),
            content: formatMessage({ id: 'select_class' })
          });
        }
      } else {
        setIsURLCorrect(false);
        // setMessage(formatMessage({ id: "country_code_not_found" }, { countryCode: locationCountryCode }));
        setMessage({
          code: '404',
          type: 'country_code_not_found',
          subtitle: formatMessage({ id: 'country_not_found' }),
          content: formatMessage({ id: 'country_code_not_found' })
        });
      }
    } else {
      setIsURLCorrect(false);
      dispatch(setSelectedCountry(null));
      setMessage({
        code: '400',
        type: 'select_country',
        subtitle: formatMessage({ id: 'country_not_found' }),
        content: formatMessage({ id: 'select_country' })
      });
    }
    setIsProgress(false);
  }, [dispatch, formatMessage, location.pathname, user.selectedCountry]);

  return (
    <>
      {!isProgress && (
        <div className="layout horizontal">
          {hasCountry && (
            <div style={{ marginTop: '10px', marginLeft: '10px' }}>
              <StyledEngineProvider injectFirst>
                <AppMenu />
              </StyledEngineProvider>
            </div>
          )}
          <div
            className="flex"
            style={{
              marginTop: '10px',
              marginRight: '10px',
              paddingLeft: '10px'
            }}>
            <ListContriesBar />
            <div style={{ paddingTop: '15px' }}>
              <AppBreadCrumbs />
            </div>
            <div style={{ paddingTop: '15px' }} className="flex">
              {isURLCorrect ? <QuestionPage metadata={metadata} /> : <Page404 source={message} />}
              <Box
                sx={{
                  bottom: 0,
                  mt: 2
                }}>
                <Footer />
              </Box>
            </div>
          </div>
        </div>
      )}
      {isProgress && <AppProgress />}
    </>
  );
}

// export default SpaceModule;
