import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import StructureService from '../../modules/space/services/structure-service';
import { useIntl } from 'react-intl';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
function TopicPathToText(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // const dataPath = computePath();
    getTopicPathLabel();
  }, []);

  async function getTopicPathLabel() {
    setIsLoading(true);
    await StructureService.getTopicPathLabel({ topicPath: props.topicPath })
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setData([]);
        setIsLoading(false);
      });
  }
  const breadcrumbs = data.map((item, index) => {
    return (
      <Typography
        component="b"
        variant="b"
        key={index}
        sx={{ fontSize: '13px' }}
        color="text.primary">
        {item}
      </Typography>
    );
  });
  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <div className="layout horizontal center wrap">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      )}
    </>
  );
}

export default TopicPathToText;
