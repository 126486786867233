import { Typography } from '@mui/material';
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation } from 'react-router-dom';
import { Class, MenuBook, Public, Subject } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectedCountry } from '../../stores/slices/userSlice';
import { useIntl } from 'react-intl';

function AppBreadCrumbs() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const location = useLocation();
  const [bread, setBread] = React.useState(null);
  const country = useSelector(selectedCountry);

  React.useEffect(() => {
    if (country != null && location != null) {
      const splitt = location.pathname.split('/');
      setBread(
        computedRealName({
          country: {
            code: splitt.at(2) != null && splitt.at(2) != '' ? splitt.at(2) : null,
            name: splitt.at(2) != null && splitt.at(2) != '' ? splitt.at(2) : null
          },
          _class: {
            code: splitt.at(3) != null && splitt.at(3) != '' ? splitt.at(3) : null,
            name: splitt.at(3) != null && splitt.at(3) != '' ? splitt.at(3) : null
          },
          subject: {
            code: splitt.at(4) != null && splitt.at(4) != '' ? splitt.at(4) : null,
            name: splitt.at(4) != null && splitt.at(4) != '' ? splitt.at(4) : null
          },
          topic: {
            code: splitt.at(5) != null && splitt.at(5) != '' ? splitt.at(5) : null,
            name: splitt.at(5) != null && splitt.at(5) != '' ? splitt.at(5) : null
          }
        })
      );
    }
  }, [location, country]);

  function computedRealName(data) {
    const input = { ...data };
    // if (input.country.code === null || input._class.code === null || input.subject.code === null || input.topic.code == null) return input;
    if (input.country.code != null) {
      if (country.code.toUpperCase() === input.country.code.toUpperCase())
        input.country.name = country.name;
      else return input;
      if (input._class.code != null) {
        const _class = country.classes[input._class.code.toUpperCase()];
        if (_class != null) input._class.name = _class.name;
        else return input;
        if (input.subject.code != null) {
          const subject = _class.subjects[input.subject.code.toUpperCase()];
          if (subject != null) input.subject.name = subject.name;
          else return input;
          if (input.topic.code != null) {
            const topic = subject.topics[input.topic.code.toUpperCase()];
            if (topic != null) input.topic.name = topic.name;
            else return input;
          }
        }
      }
    }
    return input;
  }
  function handleClick(event) {
    event.preventDefault();
  }

  return (
    <>
      {bread != null && (
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs maxItems={5} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to={'/space/'}>
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {formatMessage({ id: 'space' })}
            </Link>

            {bread && bread.country.code && (
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to={'/space/' + bread.country.code}>
                <Public sx={{ mr: 0.5 }} fontSize="inherit" />
                {bread.country.name}
              </Link>
            )}

            {bread && bread._class.code && (
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to={'/space/' + bread.country.code + '/' + bread._class.code}>
                <Class sx={{ mr: 0.5 }} fontSize="inherit" />
                {bread._class.name}
              </Link>
            )}

            {bread && bread.subject.code != null && (
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to={
                  '/space/' +
                  bread.country.code +
                  '/' +
                  bread._class.code +
                  '/' +
                  bread.subject.code
                }>
                <Subject sx={{ mr: 0.5 }} fontSize="inherit" />
                {bread.subject.name}
              </Link>
            )}

            {bread && bread.topic.code != null && (
              <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
                <MenuBook sx={{ mr: 0.5 }} fontSize="inherit" />
                {bread.topic.name}
              </Typography>
            )}
          </Breadcrumbs>
        </div>
      )}
    </>
  );
}

export default AppBreadCrumbs;
