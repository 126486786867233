import React from 'react';
import { useEffect } from 'react';
import QuestionAdd from '../components/question/add/QuestionAdd';
import QuestionList from '../components/question/list/QuestionList';

import { KeyboardArrowUp } from '@mui/icons-material';
import { Box, IconButton, Paper } from '@mui/material';
import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import QuestionImport from '../components/question/import/QuestionImport';

import { useIntl } from 'react-intl';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

function QuestionPage(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [showView, setShowView] = useState(false);
  const [tab, setTab] = useState();

  const handleTab = (event, tab) => {
    setShowView(true);
    setTab(tab);
  };
  const handleHide = (event) => {
    setShowView(false);
    setTab('');
  };

  useEffect(() => {}, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={0}
          sx={{
            border: (theme) => `0px solid ${theme.palette.divider}`
          }}
          className="layout horizontal center">
          <StyledToggleButtonGroup
            size="small"
            value={tab}
            exclusive
            onChange={handleTab}
            aria-label="text alignment"
            className="flex"
            color="primary">
            <ToggleButton value="FORM" aria-label="left aligned">
              <span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'add_question' })}</span>
            </ToggleButton>
            {/* <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} /> */}

            <ToggleButton value="IMPORT" aria-label="centered">
              <span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'import' })}</span>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <div>
            {showView && (
              <IconButton
                onClick={handleHide}
                color="secondary"
                aria-label={formatMessage({ id: 'hide_area' })}>
                <KeyboardArrowUp />
              </IconButton>
            )}
            {/* {!showView && <IconButton onClick={(event) => setShowView(true)} color="secondary" aria-label="Afficher la zone">
              <KeyboardArrowUp />
            </IconButton>} */}
          </div>
        </Paper>
      </Box>
      {showView && tab === 'FORM' && (
        <div style={{ marginTop: '10px' }}>
          <QuestionAdd metadata={props.metadata} />
        </div>
      )}
      {showView && tab === 'IMPORT' && (
        <div style={{ marginTop: '10px' }}>
          <QuestionImport metadata={props.metadata} />
        </div>
      )}
      <div style={{ marginTop: '10px' }}>
        <QuestionList metadata={props.metadata} />
      </div>
    </>
  );
}

export default QuestionPage;
