import { createSlice } from '@reduxjs/toolkit';

const initialState = false;
const connectionSlice = createSlice({
  name: 'con',
  initialState: initialState,
  reducers: {
    setCon: (state, action) => {
      return action.payload;
    }
  }
});

export const { setCon } = connectionSlice.actions;

export const selectConStatus = (state) => state.con;

export default connectionSlice.reducer;
