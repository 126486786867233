import axios from 'axios';
import cookieService from '../../../cookieService';
import KEYS from '../../../utils/key';

const authHeader = () => {
  const token = cookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      'x-access-token': token,
      'Content-Type': 'application/json'
    };
  } else {
    return {};
  }
};
const listAdminQuestions = (params, isTeacherNotif) => {
  if (isTeacherNotif) return listTeacherNotifs(params);
  const url = KEYS.backendPath + '/quiz/question/admins';
  const headers = authHeader();
  // const params = '?' + new URLSearchParams(obj).toString();
  console.log('listQuestions params ', params);
  return axios.get(url, { params: params, headers: headers });
};
const listTeacherNotifs = (params) => {
  const url = KEYS.backendPath + '/quiz/question/notifs';
  const headers = authHeader();
  // const params = '?' + new URLSearchParams(obj).toString();
  console.log('listQuestions params ', params);
  return axios.get(url, {
    params: { ...params, fromTeacher: 1 },
    headers: headers
  });
};
const listQuestions = (params, path) => {
  const url = KEYS.backendPath + '/quiz/question/';
  const headers = authHeader();
  // const params = '?' + new URLSearchParams(obj).toString();
  headers['x-resource'] = btoa(path);
  console.log('listQuestions params ', params);
  return axios.get(url, { params: params, headers: headers });
};

const addQuestion = (body, path) => {
  const url = KEYS.backendPath + '/quiz/question/add';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log('addQuestion/body = ', body);
  return axios.post(url, body, { headers: headers });
};

const editQuestion = (body, path) => {
  const url = KEYS.backendPath + '/quiz/question/update-question/' + body.questionId;
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(body);
  return axios.put(url, body, { headers: headers });
};
const setStatusQuestion = (query, path) => {
  const url = KEYS.backendPath + '/quiz/question/set-status-question';
  console.log('setStatusQuestion/url = ', url);
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  const params = { status: query.status, questionId: query.questionId }; // questionId: query.questionId,
  return axios.put(url, {}, { params: params, headers: headers });
};

const archiveQuestion = (questionId, path) => {
  const url = KEYS.backendPath + '/quiz/question/archive-question';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(questionId);
  const params = { questionId: questionId };
  return axios.put(url, {}, { params, headers: headers });
};

const deleteQuestion = (questionId, version, path) => {
  const url = KEYS.backendPath + '/quiz/question/delete-question';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(questionId);
  console.log('headers', headers);
  const params = { questionId: questionId, version: version };
  return axios.put(url, { hello: 'word' }, { params, headers: headers });
};

const unArchiveQuestion = (questionId, version, path) => {
  const url = KEYS.backendPath + '/quiz/question/desarchive-question';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(questionId);
  const params = { questionId: questionId, version: version };
  return axios.put(url, {}, { params, headers: headers });
};

// New add
const acceptChanges = (idFrom, idTo, path, isTeacher) => {
  // idFrom = "" + idFrom.replaceAll("#", "%23");
  // idTo = "" + idTo.replaceAll("#", "%23");
  console.log(path);
  const url = KEYS.backendPath + '/quiz/notif/accept-changes/' + (isTeacher ? 'teacher' : 'admin');
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  // const params = { idFrom: idFrom, idTo: idTo };
  const params = {
    versionFrom: idFrom.version,
    questionIdFrom: idFrom.questionId,
    versionTo: idTo.version,
    questionIdTo: idTo.questionId
  };
  return axios.put(url, {}, { params, headers: headers });
};

const discardChanges = (idFrom, idTo, path, isTeacher) => {
  console.log(path);
  const url = KEYS.backendPath + '/quiz/notif/discard-changes/' + (isTeacher ? 'teacher' : 'admin');

  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  // const params = { idFrom: idFrom, idTo: idTo };
  const params = {
    versionFrom: idFrom.version,
    questionIdFrom: idFrom.questionId,
    versionTo: idTo.version,
    questionIdTo: idTo.questionId
  };
  return axios.put(url, {}, { params, headers: headers });
};

const backToPrevious = (idFrom, idTo, path, isTeacher) => {
  const url =
    KEYS.backendPath + '/quiz/notif/restoration-question/' + (isTeacher ? 'teacher' : 'admin');
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  // const params = { idFrom: idFrom, idTo: idTo };
  const params = {
    versionFrom: idFrom.version,
    questionIdFrom: idFrom.questionId,
    versionTo: idTo.version,
    questionIdTo: idTo.questionId
  };
  return axios.put(url, {}, { params, headers: headers });
};

const QuestionService = {
  addQuestion,
  listAdminQuestions,
  listQuestions,
  setStatusQuestion,
  archiveQuestion,
  editQuestion,
  acceptChanges,
  discardChanges,
  backToPrevious,
  unArchiveQuestion,
  deleteQuestion
};

export default QuestionService;
