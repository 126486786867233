import { Launch, Save } from '@mui/icons-material';
import { Chip, Link } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { useIntl } from 'react-intl';
import { cancelEventPropagation, QUESTION_TYPE_COLOR } from '../../../utils/Utils';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect } from 'react';
import QuestionViewer from './QuestionViewer';
import SharedQuestionService from './question-service';
import { itemToData } from 'dynamo-converters';
import EnumQuestionType from '../../../utils/EnumQuestionType';
import { LoadingButton } from '@mui/lab';

function QuestionItemGeneric(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { index, question } = props;
  const [open, setOpen] = useState(false);
  const [loadingParent, setLoadingParent] = useState(false);
  const [loadingSubQuestion, setLoadingSubQuestion] = useState(false);
  const [subQuestions, setSubQuestions] = useState([]);

  const [item, setItem] = useState(null);

  const computePath = () => {
    const pathData = props.question.topicPath.split('#').slice(0, 3).join('.');
    return pathData;
  };

  const handleClickOpen = (e) => {
    cancelEventPropagation(e);
    console.log('Open.........');
    setOpen(true);
    setItem(props.question);
  };

  const handleClose = () => {
    setOpen(false);
    setItem(null);
  };

  const showDetailParent = (id) => {
    setLoadingParent(true);
    SharedQuestionService.viewQuestion({ questionId: id })
      .then((response) => {
        setLoadingParent(false);
        // console.log(response);
        setOpen(true);
        setItem(itemToData(response.data.question));
      })
      .catch((err) => {
        setLoadingParent(false);
      });
  };

  const getSubQuestion = async (question) => {
    setLoadingSubQuestion(true);
    const path = computePath();

    SharedQuestionService.getSubQuestion(question, path)
      .then((response) => {
        console.log(response);
        props.onSetSubQuestions(response?.data?.subQuestion);

        setLoadingSubQuestion(false);
      })
      .catch((err) => {
        setLoadingSubQuestion(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (props.question.subQuestions?.length > 0) getSubQuestion(props.question);
  }, [props.question]);
  return (
    <>
      <ListItem
        onClick={(e) => handleClickOpen(e)}
        // secondaryAction={props.actions}
        sx={{ pl: '0', pt: '0px', pb: '0px', pr: '0px', cursor: 'pointer' }}
        dense={true}
        className="layout horizontal">
        <ListItemAvatar sx={{ minWidth: '48px' }}>
          <Avatar sx={{ backgroundColor: '#7ea5b2bf' }}>{index + 1}</Avatar>
        </ListItemAvatar>
        <div className="layout horizontal flex">
          <ListItemText
            className="flex"
            disableTypography={true}
            primary={
              <div
                style={{
                  fontWeight: '400',
                  fontSize: '0.9rem',
                  color: question.isDeleted ? 'red' : 'initial'
                }}>
                {question.content}
              </div>
            }
            secondary={
              <div className="layout horizontal center">
                {question.type === EnumQuestionType.OPEN && (
                  <Chip
                    title={formatMessage({ id: 'open_answer_question' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8'
                    }}
                    label="OPEN"
                    color={QUESTION_TYPE_COLOR.OPEN}
                  />
                )}
                {question.type === EnumQuestionType.MULTI_CHOICE && (
                  <Chip
                    title={formatMessage({ id: 'MCQ' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8'
                    }}
                    label="QCM MULTI"
                    color={QUESTION_TYPE_COLOR.MULTI_CHOICE}
                  />
                )}
                {question.type === EnumQuestionType.SINGLE_CHOICE && (
                  <Chip
                    title={formatMessage({ id: 'single_choice' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8'
                    }}
                    label="QCM SINGLE"
                    color={QUESTION_TYPE_COLOR.SINGLE_CHOICE}
                  />
                )}

                <span
                  style={{
                    marginLeft: '20px',
                    fontWeight: '300',
                    fontSize: '12px'
                  }}>
                  <b>{question.mark}</b> {formatMessage({ id: 'marks' })}
                </span>
                <span
                  style={{
                    marginLeft: '10px',
                    fontWeight: '300',
                    fontSize: '12px'
                  }}>
                  <b>{question.duration}</b> {formatMessage({ id: 'minuts' })}
                </span>
                {question.images && question.images.length > 0 && (
                  <span
                    style={{
                      marginLeft: '10px',
                      fontWeight: '600',
                      fontSize: '12px'
                    }}>
                    <b>{question.images.length}</b> image(s)
                  </span>
                )}
                {(!question.images || question.images.length === 0) && (
                  <span
                    style={{
                      marginLeft: '10px',
                      fontWeight: '300',
                      fontSize: '12px'
                    }}>
                    {formatMessage({ id: 'no_image' })}
                  </span>
                )}
                {question.children &&
                  Array.isArray(question.children) &&
                  question.children.length > 0 && (
                    <span
                      style={{
                        marginLeft: '10px',
                        padding: '5px',
                        backgroundColor: '#EAEAEA',
                        fontSize: '12px'
                      }}>
                      <b>{question.children.length}</b> copies
                    </span>
                  )}

                {question.apply === 'YES' && (
                  <Chip
                    title={formatMessage({ id: 'apply' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8',
                      marginLeft: '10px'
                    }}
                    label={formatMessage({ id: 'apply' })}
                    color="primary"
                  />
                )}
                {question.apply === 'NO' && (
                  <Chip
                    title={formatMessage({ id: 'not_apply' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8',
                      marginLeft: '10px'
                    }}
                    label={formatMessage({ id: 'not_apply' })}
                    color="secondary"
                  />
                )}
                {question.parent && (
                  // question.topQuestion && (
                  <>
                    {!loadingParent && (
                      <Link
                        underline="none"
                        component="button"
                        variant="span"
                        onClick={(event) => {
                          cancelEventPropagation(event);
                          // showDetailParent(question.topQuestion);
                          if (props.onShowParentDetail) props.onShowParentDetail(event);
                        }}>
                        <span
                          style={{ marginLeft: '10px', color: 'blue' }}
                          className="layout horizontal center">
                          <span
                            style={{
                              fontSize: '13px',
                              lineHeight: '0.8'
                            }}>
                            {formatMessage({ id: 'see_the_source' })}
                          </span>
                          <Launch sx={{ fontSize: '1rem !important' }} fontSize="inherit" />
                        </span>
                      </Link>
                    )}
                    {loadingParent && (
                      <span
                        style={{ marginLeft: '10px', color: 'blue' }}
                        className="layout horizontal center">
                        <span
                          style={{
                            fontSize: '13px',
                            lineHeight: '0.8'
                          }}>
                          {formatMessage({ id: 'loading_source' })}
                        </span>
                      </span>
                    )}
                  </>
                )}
                {/* question.subQuestions?.length > 0 && !loadingSubQuestion && (
                  <Button
                    variant="outlined"
                    style={{ marginLeft: '10px', color: 'blue', textTransform: 'none' }}
                    className="layout horizontal center"
                    onClick={(event) => {
                      cancelEventPropagation(event);
                      getSubQuestion(question);
                    }}>
                    <span
                      style={{
                        fontSize: '13px',
                        lineHeight: '0.8'
                      }}>
                      Sous questions
                    </span>
                    <ArrowDropDown sx={{ fontSize: '1rem !important' }} fontSize="inherit" />
                  </Button>
                    )*/}
                {loadingSubQuestion && (
                  <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    style={{ marginLeft: '10px', color: 'blue', textTransform: 'none' }}>
                    <span
                      style={{
                        fontSize: '13px',
                        lineHeight: '0.8'
                      }}>
                      Sous questions
                    </span>
                  </LoadingButton>
                )}
              </div>
            }
          />
          <div className="layout horizontal center">{props.actions}</div>
        </div>
      </ListItem>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent sx={{ padding: '0px 10px 10px 10px' }}>
          {item && (
            <QuestionViewer
              onShowParentDetail={(id) => {
                setOpen(false);
                setItem(null);
                showDetailParent(id);
              }}
              question={item}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{formatMessage({ id: 'close' })}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default QuestionItemGeneric;
