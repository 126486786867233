import { Send } from '@mui/icons-material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper, useTheme } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AppTitle from '../../../../../components/shared/AppTitle';
import {
  selectEditQuestion,
  selectFormatQuestion,
  selectMulti,
  selectSingle,
  selectOpen,
  selectGeneric,
  selectMetadata
} from '../../../../../stores/slices/editQuestionSlice';
import QuestionService from '../../../services/question-service';

import QuestionEditGeneric from './QuestionEditGeneric';
import QuestionEditMulti from './QuestionEditMulti';
import QuestionEditOpen from './QuestionEditOpen';
import QuestionEditSingle from './QuestionEditSingle';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import EnumQuestionType from '../../../../../utils/EnumQuestionType';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

function QuestionEdit(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const theme = useTheme();
  const metadata = useSelector(selectMetadata);
  const question = useSelector(selectEditQuestion);

  const formatedQuestion = useSelector(selectFormatQuestion);
  console.log('THE QUESTION TO EDIT', question);
  const generic = useSelector(selectGeneric);
  const optionMulti = useSelector(selectMulti);
  const optionSingle = useSelector(selectSingle);
  const optionOpen = useSelector(selectOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const doCancelForm = () => {
    // fermer le dialog d'edition
    props.onCloseEdit(null);
  };
  const doSubmitForm = (event) => {
    console.log('doSubmit form');
    setErrorMessages([]);
    setIsLoading(true);
    setIsSubmited(true);
    const path = `${metadata.country}.${metadata.class}.${metadata.subject}`;
    // const topicPath = `${metadata.country}#${metadata.class}#${metadata.subject}#${metadata.topic}`;
    /* const { content, mark, duration, images, type, questionId } = question;
    let data = { content, mark, duration, images, type, questionId, topicPath };*/
    const data = generic;
    console.log('generic', data);
    switch (data.type) {
      case EnumQuestionType.OPEN:
        data.answer = optionOpen.answer;
        data.answerTags = optionOpen.answerTags;
        data.explanation = optionOpen.explanation;
        break;
      case EnumQuestionType.MULTI_CHOICE:
        data.options = optionMulti.options;
        break;
      case EnumQuestionType.SINGLE_CHOICE:
        data.answer = optionSingle.answer;
        data.options = optionSingle.options;
        break;
      default:
        console.log('Type not found');
        return;
    }
    console.log('data2', data);
    // data.type = "CLOSE";

    QuestionService.editQuestion(data, path)
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        // fermer le dialog d'edition
        console.log('formatedQuestion', formatedQuestion);
        if (props.onCloseEdit) props.onCloseEdit(formatedQuestion);
        enqueueSnackbar(formatMessage({ id: 'Question_edited_successfully' }), {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      })
      .catch((err) => {
        console.log(err);
        setIsSubmited(false);
        enqueueSnackbar(formatMessage({ id: 'unkown_error' }), {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });

        setIsLoading(false);
        console.log('err?.response', err?.response);
        const errors = [];
        // console.log(err.response.data.code);
        switch (err?.response?.data?.code) {
          case 'VALIDATION_ERROR':
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'content'))
              errors.push({
                code: 'content',
                message: formatMessage({
                  id: 'question_content_must_not_be_empty'
                })
              });
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'answer'))
              errors.push({
                code: 'answer',
                message: formatMessage({ id: 'answer_most_not_be_empty' })
              });
            if (
              Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'explanation')
            )
              errors.push({
                code: 'explanation',
                message: 'Le champ explication est requis pour cette question'
              });
            if (
              Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'answerTags')
            )
              errors.push({
                code: 'answerTags',
                message: formatMessage({ id: 'you_most_enter_At_list_2_tags' })
              });
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'images'))
              errors.push({
                code: 'images',
                message: formatMessage({ id: 'it_must_be_images' })
              });
            if (Object.prototype.hasOwnProperty.call(err?.response?.data?.error ?? {}, 'options'))
              errors.push({
                code: 'options',
                message: formatMessage({
                  id: 'answer_options_must_be_filled_in'
                })
              });
            break;
          case 'EXPLANATION_ERROR':
            errors.push({
              code: 'explanation',
              message: 'Le Champ explication est requis pour les réponses juste'
            });
            break;
          case 'PARENT_ERROR':
            console.log(err);
            break;
          case 'MULTI_OPTIONS_LENGTH_ERROR':
            errors.push({
              code: 'MULTI_OPTIONS_LENGTH_ERROR',
              message: formatMessage({
                id: 'options_must_have_at_least_two_valid_values'
              })
            });
            break;
          case 'TYPE_QUESTION_NOT_FOUND':
            errors.push({
              code: 'TYPE_QUESTION_NOT_FOUND',
              message: formatMessage({
                id: 'the_type_of_this_question_does_not_exist'
              })
            });
            break;
          case 'SAVE_QUESTION_ERROR':
            errors.push({
              code: 'SAVE_QUESTION_ERROR',
              message: formatMessage({ id: 'save_not_complet' })
            });
            break;
          default:
            console.log('Type not found');
            return;
        }
        console.log('errors = ', errors);
        setErrorMessages(errors);
      });
  };

  return (
    <Paper elevation={0} sx={{ p: '10px', mt: 1 }}>
      <div className="layout horizontal center" style={{ paddingBottom: '15px' }}>
        <div className="flex">
          <AppTitle
            icon={<ModeEditOutlineOutlinedIcon />}
            title={formatMessage({ id: 'edit_question' })}
            subtitle={formatMessage({ id: 'fill_all_informations' })}
          />
        </div>
      </div>
      <div style={{ marginTop: '25px' }}>
        <QuestionEditGeneric
          metadata={metadata}
          submitting={isLoading}
          errorMessages={errorMessages}
          question={question}
        />
        {question && question.type === EnumQuestionType.OPEN && (
          <QuestionEditOpen
            submitting={isLoading}
            errorMessages={errorMessages}
            question={question}
          />
        )}
        {question && question.type === EnumQuestionType.MULTI_CHOICE && (
          <QuestionEditMulti
            submitting={isLoading}
            errorMessages={errorMessages}
            question={question}
          />
        )}
        {question && question.type === EnumQuestionType.SINGLE_CHOICE && (
          <QuestionEditSingle
            submitting={isLoading}
            errorMessages={errorMessages}
            question={question}
          />
        )}
      </div>
      <div style={{ marginTop: '10px' }} className="layout horizontal center end-justified">
        <span>
          <LoadingButton
            size="large"
            variant="outlined"
            sx={{ marginRight: 1 }}
            onClick={doCancelForm}
            disabled={isSubmited}>
            {formatMessage({ id: 'cancel' })}
          </LoadingButton>
        </span>
        <span>
          <LoadingButton
            loading={isLoading}
            size="large"
            loadingPosition="start"
            variant="contained"
            color="primary"
            type="submit"
            onClick={doSubmitForm}
            startIcon={<Send />}
            sx={{
              fontWeight: 'bold',
              textTransform: 'capitalize'
            }}>
            {isLoading ? formatMessage({ id: 'in_progress' }) : formatMessage({ id: 'save' })}
          </LoadingButton>
        </span>
      </div>
    </Paper>
  );
}
export default QuestionEdit;
