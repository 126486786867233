import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { Box, List, Chip, Stack } from '@mui/material';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect } from 'react';

import { itemToData, dataToItem } from 'dynamo-converters';
import SharedQuestionService from '../../../../../components/shared/question/question-service';
import QuestionViewer from '../../../../../components/shared/question/QuestionViewer';
import { cancelEventPropagation, QUESTION_TYPE_COLOR } from '../../../../../utils/Utils';
import EnumQuestionType from '../../../../../utils/EnumQuestionType';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function QuestionItemImport(props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { index, question } = props;
  const [open, setOpen] = useState(false);
  const [loadingParent, setLoadingParent] = useState(false);
  const [loadingSubQuestion, setLoadingSubQuestion] = useState(false);
  const [subQuestions, setSubQuestions] = useState([]);
  const [selectSubQuestion, setSelectSubQuestion] = useState(props.question.withSubquestion);

  const [item, setItem] = useState(null);

  const computePath = () => {
    const pathData = props.question.topicPath.split('#').slice(0, 3).join('.');
    return pathData;
  };

  const moveSubQuestionWithSubQuestion = (item) => {
    item.hasMove = true;
    if (props.root.questionId === item.questionId) props.importWithoutSubQuestion();
    else {
      const donnees = props.root.subQuestions.map((element) => {
        const data = itemToData(element);
        if (data.questionId === item.questionId) {
          data.hasMove = true;
        }
        return dataToItem(data);
      });
      props.root.subQuestions = donnees;
      props.importSubQuestion(donnees);
    }
  };

  const handleClickOpen = (e) => {
    cancelEventPropagation(e);
    console.log('Open.........');
    setOpen(true);
    setItem(props.question);
  };

  const handleClose = () => {
    setOpen(false);
    setItem(null);
  };

  const showDetailParent = (id) => {
    setLoadingParent(true);
    SharedQuestionService.viewQuestion({ questionId: id })
      .then((response) => {
        setLoadingParent(false);
        setOpen(true);
        setItem(itemToData(response.data.question));
      })
      .catch((err) => {
        setLoadingParent(false);
      });
  };

  const getSubQuestion = async (parentQuestion) => {
    setLoadingSubQuestion(true);
    const path = computePath();

    SharedQuestionService.getSubQuestion(parentQuestion, path)
      .then((response) => {
        question.subQuestions = response?.data?.subQuestion;
        setLoadingSubQuestion(false);
      })
      .catch((err) => {
        setLoadingSubQuestion(false);
        console.log(err);
      });
  };
  const handleChange = (event) => {
    setSelectSubQuestion(event.target.checked);
    props.question.withSubquestion = event.target.checked;
    /* if (event.target.checked) {
      props.question.subQuestions = props.question.subQuestions.map((item) => {
        const element = itemToData(item);
        element.hasMove = true;
        return dataToItem(element);
      });
    } else {
      props.question.subQuestions = props.question.subQuestions.map((item) => {
        const element = itemToData(item);
        element.hasMove = false;
        return dataToItem(element);
      });
    } */
  };
  useEffect(() => {
    if (props.question.subQuestions?.length > 0) getSubQuestion(props.question);
  }, [props.question]);
  return (
    <>
      <ListItem
        onClick={(e) => handleClickOpen(e)}
        sx={{ pl: '0', pt: '5px', pb: '5px', pr: '0px', cursor: 'pointer' }}
        dense={true}
        className="">
        <ListItemAvatar sx={{ minWidth: '48px' }}>
          <Avatar sx={{ backgroundColor: '#7ea5b2bf' }}>{index + 1}</Avatar>
        </ListItemAvatar>
        <div className="layout horizontal flex">
          <ListItemText
            className="flex"
            disableTypography={true}
            primary={
              <div
                style={{
                  fontWeight: '400',
                  fontSize: '0.9rem',
                  color: question.isDeleted ? 'red' : 'initial'
                }}>
                {question.content}
              </div>
            }
            secondary={
              <div className="layout horizontal center">
                {question.type === EnumQuestionType.OPEN && (
                  <Chip
                    title={formatMessage({ id: 'open_answer_question' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8'
                    }}
                    label="OPEN"
                    color={QUESTION_TYPE_COLOR.OPEN}
                  />
                )}
                {question.type === EnumQuestionType.MULTI_CHOICE && (
                  <Chip
                    title={formatMessage({ id: 'MCQ' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8'
                    }}
                    label="QCM MULTI"
                    color={QUESTION_TYPE_COLOR.MULTI_CHOICE}
                  />
                )}
                {question.type === EnumQuestionType.SINGLE_CHOICE && (
                  <Chip
                    title={formatMessage({ id: 'single_choice' })}
                    size="small"
                    style={{
                      fontSize: '10px',
                      height: '18px',
                      lineHeight: '0.8'
                    }}
                    label="QCM SINGLE"
                    color={QUESTION_TYPE_COLOR.SINGLE_CHOICE}
                  />
                )}

                <span
                  style={{
                    marginLeft: '20px',
                    fontWeight: '300',
                    fontSize: '12px'
                  }}>
                  <b>{question.mark}</b> {formatMessage({ id: 'marks' })}
                </span>
                <span
                  style={{
                    marginLeft: '10px',
                    fontWeight: '300',
                    fontSize: '12px'
                  }}>
                  <b>{question.duration}</b> {formatMessage({ id: 'minuts' })}
                </span>
                {question.images && question.images.length > 0 && (
                  <span
                    style={{
                      marginLeft: '10px',
                      fontWeight: '600',
                      fontSize: '12px'
                    }}>
                    <b>{question.images.length}</b> image(s)
                  </span>
                )}
                {(!question.images || question.images.length === 0) && (
                  <span
                    style={{
                      marginLeft: '10px',
                      fontWeight: '300',
                      fontSize: '12px'
                    }}>
                    {formatMessage({ id: 'no_image' })}
                  </span>
                )}
                {question.children &&
                  Array.isArray(question.children) &&
                  question.children.length > 0 && (
                    <span
                      style={{
                        marginLeft: '10px',
                        padding: '5px',
                        backgroundColor: '#EAEAEA',
                        fontSize: '12px'
                      }}>
                      <b>{question.children.length}</b> copies
                    </span>
                  )}
              </div>
            }
          />
          <div className="layout horizontal center">{props.actions}</div>
        </div>
      </ListItem>
      {question.subQuestions?.length > 0 && question.subQuestions[0].content && (
        <Box
          sx={{
            paddingLeft: '25px',
            marginBottom: '5px'
          }}>
          <Stack
            direction="row"
            spacing={5}
            sx={{ verticalAlign: 'middle', alignItems: 'baseline' }}>
            <Box style={{ color: '#656565', marginRight: '5px' }}>
              {formatMessage({ id: 'list_of_subquestion' })}
            </Box>
            <Box style={{ color: '#656565', marginLeft: '5px' }}>
              <FormControlLabel
                control={<Checkbox checked={selectSubQuestion} size="small" />}
                onChange={handleChange}
                label={formatMessage({ id: 'import_with_subquestion' })}
                size="8px"
                sx={{ fontWeight: '100' }}
              />
            </Box>
          </Stack>

          <List
            sx={{
              width: '100%',
              pt: '0px',
              pb: '10px',
              bgcolor: 'background.paper'
            }}>
            {question.subQuestions.map((item, index) => (
              <div key={index}>
                <QuestionItemImport
                  metadata={props.metadata}
                  root={props.root}
                  question={itemToData(item)}
                  index={index}
                  importSubQuestion={(e) => props.importSubQuestion(e)}
                  actions={
                    props.question.subQuestions?.length > 0 && (
                      <Button
                        sx={{ ml: 1, width: '5px' }}
                        variant="outlined"
                        size="small"
                        onClick={(event) => {
                          cancelEventPropagation(event);
                          itemToData(item).hasMove = true;
                          moveSubQuestionWithSubQuestion(itemToData(item));
                        }}
                        disabled={itemToData(item).hasMove}
                        aria-label="move selected right">
                        <ArrowForwardIosOutlined fontSize="small" />
                      </Button>
                    )
                  }
                />
              </div>
            ))}
          </List>
        </Box>
      )}
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent sx={{ padding: '0px 10px 10px 10px' }}>
          {item && (
            <QuestionViewer
              onShowParentDetail={(id) => {
                setOpen(false);
                setItem(null);
                showDetailParent(id);
              }}
              question={item}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{formatMessage({ id: 'close' })}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default QuestionItemImport;
