import { createSlice } from '@reduxjs/toolkit';

const initialState = { type: 'OPEN', mark: '1', duration: '1' };

const subQuestionSlice = createSlice({
  name: 'subQuestion',
  initialState: initialState,
  reducers: {
    setGeneric: (state, action) => {
      return { ...state, ...action.payload };
    },

    setMulti: (state, action) => {
      return { ...state, multi: { ...action.payload } };
    },

    setOpen: (state, action) => {
      console.log('setOpen/action = ', action);
      return { ...state, open: { ...action.payload } };
    },
    setSingle: (state, action) => {
      return { ...state, single: { ...action.payload } };
    },
    setColumn: (state, action) => {
      return { ...state, column: { ...action.payload } };
    },
    setTextMatch: (state, action) => {
      return { ...state, textMatch: { ...action.payload } };
    },
    resetData: (state) => {
      return { type: 'OPEN', mark: '1', duration: '1' };
    }
  }
});

export const { setGeneric, setMulti, setOpen, setSingle, setColumn, setTextMatch, resetData } =
  subQuestionSlice.actions;

export const selectSubQuestion = (state) => state.subQuestion;
export const selectMulti = (state) => (state.subQuestion != null ? state.subQuestion.multi : {});
export const selectSingle = (state) => (state.subQuestion != null ? state.subQuestion.single : {});
export const selectOpen = (state) => (state.subQuestion != null ? state.subQuestion.open : {});

export const subQuestionReducer = subQuestionSlice.reducer;
