import { createSlice } from '@reduxjs/toolkit';

const initialState = { type: 'OPEN', mark: '1', duration: '1' };

const newQuestionSlice = createSlice({
  name: 'newQuestion',
  initialState: initialState,
  reducers: {
    setGeneric: (state, action) => {
      console.log('SET GENERIC', state, action);
      return { ...state, ...action.payload };
    },

    setMulti: (state, action) => {
      return { ...state, multi: { ...action.payload } };
    },

    setOpen: (state, action) => {
      console.log('setOpen/action = ', action);
      return { ...state, open: { ...action.payload } };
    },
    setSingle: (state, action) => {
      return { ...state, single: { ...action.payload } };
    },
    setColumn: (state, action) => {
      return { ...state, column: { ...action.payload } };
    },
    setTextMatch: (state, action) => {
      return { ...state, textMatch: { ...action.payload } };
    },
    resetData: (state, action) => {
      return { type: 'OPEN', mark: '1', duration: '1', ...action.payload };
    }
  }
});

export const { setGeneric, setMulti, setOpen, setSingle, setColumn, setTextMatch, resetData } =
  newQuestionSlice.actions;

export const selectNewQuestion = (state) => state.newQuestion;
export const selectMulti = (state) => (state.newQuestion != null ? state.newQuestion.multi : {});
export const selectSingle = (state) => (state.newQuestion != null ? state.newQuestion.single : {});
export const selectOpen = (state) => (state.newQuestion != null ? state.newQuestion.open : {});
export const selectGeneric = (state) => {
  if (state.newQuestion == null) return {};
  return (({ open, single, multi, column, textMatch, ...o }) => o)(state.newQuestion);
};
export const newQuestionReducer = newQuestionSlice.reducer;
