import axios from 'axios';
import cookieService from '../../../cookieService';
import KEYS from '../../../utils/key';

const authHeader = () => {
  const token = cookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      'x-access-token': token,
      'Content-Type': 'application/json'
    };
  } else {
    return {};
  }
};

const listQuestions = (params, path) => {
  const url = KEYS.backendPath + '/quiz/question/';
  const headers = authHeader();
  // const params = '?' + new URLSearchParams(obj).toString();
  console.log('listQuestions/params=', params);
  headers['x-resource'] = btoa(path);
  return axios.get(url, { params: params, headers: headers });
};

const addSubQuestion = (body, path, parent) => {
  const url = KEYS.backendPath + '/quiz/question/add-subquestion';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  body.parentId = parent.questionId;
  body.version = 'SUBQUESTION';
  console.log('addQuestion/body = ', body);
  return axios.post(url, body, { headers: headers });
};

const addQuestion = (body, path) => {
  const url = KEYS.backendPath + '/quiz/question/add';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  // console.log('addQuestion/body = ', body);
  return axios.post(url, body, { headers: headers });
};
const editQuestion = (body, path) => {
  const url = KEYS.backendPath + '/quiz/question/update-question';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(body);
  return axios.put(url, body, { headers: headers });
};
const setStatusQuestion = (query, path) => {
  const url = KEYS.backendPath + '/quiz/question/set-status-question';
  console.log('setStatusQuestion/url = ', url);
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  const params = { status: query.status, questionId: query.questionId, version: query.version }; // questionId: query.questionId,
  return axios.put(url, {}, { params: params, headers: headers });
};

const archiveQuestion = (questionId, version, path) => {
  const url = KEYS.backendPath + '/quiz/question/archive-question';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(questionId);
  const params = { questionId: questionId, version: version };
  return axios.put(url, {}, { params, headers: headers });
};

const importQuestion = (body, path) => {
  const url = KEYS.backendPath + '/quiz/question/import';
  const headers = authHeader();
  headers['x-resource'] = btoa(path);
  console.log(body);
  return axios.post(url, body, { headers: headers });
};

const QuestionService = {
  addQuestion,
  addSubQuestion,
  listQuestions,
  setStatusQuestion,
  archiveQuestion,
  editQuestion,
  importQuestion
};

export default QuestionService;
