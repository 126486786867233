import { useTheme } from '@emotion/react';
import { Avatar, Typography } from '@mui/material';

function AppTitle(props) {
  const { title, subtitle, icon } = props;
  const theme = useTheme();

  return (
    <div className="layout horizontal center">
      <div>
        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{icon}</Avatar>
      </div>
      <div className="layout vertical" style={{ marginLeft: 10 }}>
        <Typography sx={{ fontSize: '18px' }} variant="b" component="b" color="primary">
          {title}
        </Typography>
        <small style={{ fontSize: '14px' }}>{subtitle}</small>
      </div>
    </div>
  );
}

export default AppTitle;
