const computeXAccessRessource = (path) => {
  if (path == null || path.length < 3) return path;
  let tPath = path.split('/');
  tPath = tPath.slice(1, tPath.length - 1);
  console.log('tPath', tPath);
  const realPath = tPath.join('.').toUpperCase();
  console.log('x-access-ressouce:', realPath);
  return realPath;
};

export { computeXAccessRessource };
